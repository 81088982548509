<template>
  <v-card class="upload-card" elevation="0" :data-popular-text="showFreeBadge ? t('hero.upload.free_trial_badge') : null">
    <v-card-text>
      <div v-if="!showFreeBadge" class="model-selector mb-4">
        <v-slider
          v-model="selectedModel"
          :ticks="modelTicks"
          :labels="modelLabels"
          :max="1"
          :min="0"
          :step="1"
          :disabled="isUploading"
          show-ticks="always"
          density="comfortable"
          color="primary"
          track-color="grey-lighten-1"
        >
          <template v-slot:prepend>
            <v-icon color="primary">mdi-lightning-bolt</v-icon>
          </template>
          <template v-slot:append>
            <v-icon color="primary">mdi-star</v-icon>
          </template>
        </v-slider>
        <div class="text-caption text-grey-darken-1 text-center">
          {{ selectedModelDescription }}
        </div>
      </div>

      <div 
        class="upload-zone pa-8"
        :class="{ 'drag-over': isDragging }"
        :disabled="isUploading"
        @dragenter.prevent="isDragging = true"
        @dragover.prevent="isDragging = true"
        @dragleave.prevent="isDragging = false"
        @drop.prevent="handleDrop"
        @click="triggerFileInput"
      >
        <v-icon size="64" :color="isUploading ? (progress <= 33 ? 'blue' : progress <= 66 ? 'orange' : progress <= 99 ? 'green' : 'primary') : 'grey-lighten-1'">
          {{ isUploading ? (progress <= 33 ? 'mdi-cloud-upload' : progress <= 66 ? 'mdi-cog' : progress <= 99 ? 'mdi-rocket' : 'mdi-check-circle') : 'mdi-cloud-upload' }}
        </v-icon>
        <v-progress-circular
            v-if="isUploading"
            :size="16"
            :width="2"
            color="primary"
            indeterminate
            class="ml-2"
        ></v-progress-circular>
        <div class="d-flex align-center justify-center mt-4">
          <span class="text-body-1">
            {{ uploadStatusText }}
          </span>          
        </div>
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          class="hidden-input"
          @change="handleFileInput"
          :disabled="isUploading"
        >
      </div>
      
      <v-alert
        v-if="errorMessage"
        type="error"
        class="mt-4"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
      
      <div v-if="selectedFile" class="selected-file mt-4">
        <v-chip
          closable
          @click:close="clearFile"
          color="primary"
          variant="outlined"
          class="file-chip"
        >
          {{ selectedFile.name }} ({{ formatFileSize(selectedFile.size) }})
        </v-chip>
      </div>
      <v-progress-linear 
        v-if="isUploading" 
        v-model="progress" 
        :key="progress"
        height="10" 
        color="primary"
        class="mt-4"
      ></v-progress-linear>

      <UploadFeedbackModal
        v-model="showFeedbackModal"
        :filename="selectedFile?.name"
        @submit="handleFeedbackSubmit"
        @skip="handleFeedbackSkip"
      />
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { trackImageUpload, trackRateLimitError } from '@/utils/analytics';
import { useAuthStore } from '@/stores/auth';
// import { useRouter } from 'vue-router';
import { getIdToken } from 'firebase/auth';
import { useStore } from 'vuex';
import UploadFeedbackModal from './UploadFeedbackModal.vue'

const emit = defineEmits(['upload-success', 'upload-error', 'upload-state-change']);
const { t, locale } = useI18n();

const fileInput = ref(null);
const selectedFile = ref(null);
const isDragging = ref(false);
const isUploading = ref(false);
const errorMessage = ref('');
const progress = ref(0);

const authStore = useAuthStore();
// const router = useRouter();

const selectedModel = ref(0); // 0 = fast, 1 = high quality

const modelTicks = {
  0: t('hero.upload.models.fast'),
  1: t('hero.upload.models.quality')
};

const modelLabels = computed(() => ({
  0: t('hero.upload.models.fast_label'),
  1: t('hero.upload.models.quality_label')
}));

const selectedModelDescription = computed(() => {
  return selectedModel.value === 0 
    ? t('hero.upload.models.fast_description')
    : t('hero.upload.models.quality_description');
});

const uploadStatusText = computed(() => {
  if (!isUploading.value) {
    return t('hero.upload.dragDrop');
  }
  
  if (progress.value <= 10) {
    return t('hero.upload.stages.hint1');
  } else if (progress.value <= 20) {
    return t('hero.upload.stages.hint2');
  } else if (progress.value <= 30) {
    return t('hero.upload.stages.hint3');
  } else if (progress.value <= 40) {
    return t('hero.upload.stages.hint4');
  } else if (progress.value <= 50) {
    return t('hero.upload.stages.analyzing');
  } else if (progress.value <= 60) {
    return t('hero.upload.stages.layoutAnalysis');
  } else if (progress.value <= 70) {
    return t('hero.upload.stages.spaceEfficiency');
  } else if (progress.value <= 80) {
    return t('hero.upload.stages.lightingAnalysis');
  } else if (progress.value <= 90) {
    return t('hero.upload.stages.generatingSuggestions');
  } else if (progress.value <= 99) {
    return t('hero.upload.stages.finishing');
  }
  return t('hero.upload.stages.creating');
});

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileInput = async (event) => {
  const file = event.target.files[0];
  if (file) {
    await validateAndSetFile(file);
    if (selectedFile.value) {
      handleUpload();
    }
  }
};

const handleDrop = async (event) => {
  isDragging.value = false;
  const file = event.dataTransfer.files[0];
  if (file) {
    await validateAndSetFile(file);
    if (selectedFile.value) {
      handleUpload();
    }
  }
};

const validateAndSetFile = (file) => {
  errorMessage.value = '';
  
  const validTypes = ['image/jpeg', 'image/png'];
  const maxSize = 10 * 1024 * 1024; // 10MB
  
  if (!validTypes.includes(file.type)) {
    errorMessage.value = t('hero.upload.errors.fileType');
    return;
  }
  
  if (file.size > maxSize) {
    errorMessage.value = t('upload.errors.fileSize');
    return;
  }
  
  selectedFile.value = file;
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const clearFile = () => {
  selectedFile.value = null;
  errorMessage.value = '';
  if (fileInput.value) {
    fileInput.value.value = '';
  }
};

function startProgressAnimation() {
  const interval = setInterval(() => {
    progress.value += 1;
    if (progress.value >= 300) {
      clearInterval(interval);
    }
  }, selectedModel.value === 0 ? 600 : 1200);
}

const uploadFile = async () => {
  isUploading.value = true;
  emit('upload-state-change', true);
  selectedModel.value = store.state.modelQuality === 'fast' ? 0 : 1;
  
  store.commit('setUserHadPremiumWhenUploaded', authStore.credits > 0);
  
  startProgressAnimation();
  
  try {
    const formData = new FormData();
    formData.append('floorplan', selectedFile.value);
    formData.append('language', locale.value);
    formData.append('model', selectedModel.value === 0 ? 'fast' : 'quality');
    
    let headers = { 'Content-Type': 'multipart/form-data' };
    if (authStore.isAuthenticated) {
      const token = await getIdToken(authStore.user);
      headers.Authorization = `Bearer ${token}`;
    }
    
    const response = await axios.post('/api/upload', formData, { headers });

    if (!response.data.success) {
      throw new Error(response.data.error.message);
    }    
    emit('upload-success', response.data.data);
    
  } catch (error) {
    // Handle specific HTTP status codes
    if (error.response?.status === 429) {
      const errorData = error.response.data;
      // Track rate limit error specifically
      trackRateLimitError(selectedFile.value.size, selectedFile.value.name);
      
      if (errorData?.isI18nKey) {
        errorMessage.value = t(errorData.message);
      } else {
        errorMessage.value = t('upload.errors.uploadLimit.message');
      }
    } 
    // Handle file upload errors
    else if (error.response?.status === 400) {
      if (error.response.data.error?.code === 'LIMIT_FILE_SIZE') {
        errorMessage.value = t('upload.errors.fileSize');
      } else if (error.response.data.error?.code === 'NO_FILE') {
        errorMessage.value = t('upload.errors.no_file');
      } else if (error.response.data.error?.code === 'NO_FLOORPLAN_DETECTED') {
        errorMessage.value = t('upload.errors.noFloorplan');
      } else {
        errorMessage.value = t('upload.errors.upload_error');
      }
    }
    // Handle AI provider errors
    else if (error.response?.status === 503) {
      errorMessage.value = t('upload.errors.rateLimit');
    }
    // Handle floorplan service errors
    else if (error.response?.data?.error?.code) {
      const errorCode = error.response.data.error.code.toLowerCase();
      const errorKeys = {
        'no_floorplan_detected': 'noFloorplan',
        'file_not_found': 'upload_error',
        'ai_provider_error': 'provider_error',
        'invalid_provider': 'provider_error',
        'analysis_error': 'provider_error',
        'all_providers_failed': 'provider_error'
      };
      
      const translationKey = errorKeys[errorCode] || errorCode;
      errorMessage.value = t(`upload.errors.${translationKey}`, {
        fallback: error.response.data.error.message
      });
    }
    // Handle other errors
    else {
      errorMessage.value = t('upload.errors.networkError');
    }
    
    emit('upload-error', errorMessage.value);
  } finally {
    isUploading.value = false;
    progress.value = 0;
    emit('upload-state-change', false);
  }
};

const showFeedbackModal = ref(false)
const feedbackSubmitted = ref(false)

const handleUpload = async () => {
  // Track upload attempt before starting upload
  trackImageUpload(selectedFile.value.size, 'attempt', selectedFile.value.name)
  
  store.commit('resetAnalysisData');  
  
  // Show feedback modal before starting upload
  if (!feedbackSubmitted.value) {
    showFeedbackModal.value = true
    return
  }
  
  await uploadFile()
}

// Modify handleFeedbackSubmit function
const handleFeedbackSubmit = async (feedback) => {
  store.commit('setUserIntent', feedback)
  
  showFeedbackModal.value = false
  feedbackSubmitted.value = true
  await uploadFile()
}

const handleFeedbackSkip = async () => {
  showFeedbackModal.value = false
  feedbackSubmitted.value = true
  // Continue with upload
  await uploadFile()
}

const showFreeBadge = computed(() => {
  return !authStore.isAuthenticated || authStore.credits <= 0;
});

const store = useStore();

watch(selectedModel, (newValue) => {
  store.commit('setModelQuality', newValue === 0 ? 'fast' : 'quality');
});

// Initialize from store
onMounted(() => {
  selectedModel.value = store.state.modelQuality === 'fast' ? 0 : 1;
});
</script>

<style scoped>
.upload-card {
  background: white;
  border: 2px dashed var(--border-color);
  border-radius: 16px !important;
  transition: all 0.3s ease;
  animation: glowingBorder 2s ease-in-out infinite;
  box-shadow: 0 0 20px 0 var(--primary);
  position: relative;
}

@keyframes glowingBorder {
  0% {
    box-shadow: 0 0 0 0 var(--primary);
    border-color: var(--border-color);
  }
  50% {
    box-shadow: 0 0 15px 0 var(--primary);
    border-color: var(--primary);
  }
  100% {
    box-shadow: 0 0 0 0 var(--primary);
    border-color: var(--border-color);
  }
}

.upload-zone {
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 12px;
  background: var(--border-color);
}

.upload-zone:hover {
  background: var(--background);
}

.drag-over {
  background: var(--background);
  border-color: var(--primary);
}

.upload-zone .v-icon {
  transition: all 0.3s ease;
}

.upload-zone:hover .v-icon {
  color: var(--primary) !important;
  transform: translateY(-5px);
}

.hidden-input {
  display: none;
}

.file-chip {
  background: white !important;
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  font-weight: 500;
}

:deep(.v-alert) {
  border-radius: 12px;
}

:deep(.v-alert.v-alert--variant-tonal) {
  background: #fee2e2;
  color: #991b1b;
}

.upload-card:hover {
  border-color: var(--primary);
  animation: none;
  box-shadow: 0 0 20px 0 var(--primary);
}

.upload-card::before {
  content: attr(data-popular-text);
  position: absolute;
  top: 8px;
  right: 8px;
  background: var(--primary);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 2;
  display: none;
}

.upload-card[data-popular-text]::before {
  display: block;
}

.model-selector {
  padding: 16px 16px 0 16px;
  margin-top: 24px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

:deep(.v-slider .v-slider-track__fill) {
  background-color: var(--primary);
}

:deep(.v-slider .v-slider-thumb__surface) {
  border-color: var(--primary);
}

:deep(.v-slider .v-slider-thumb__surface::before) {
  background-color: var(--primary);
}
</style> 