import 'klaro/dist/klaro.css';
import { klaroConfig } from '../config/klaro-config';


// Initialize Klaro
export function initializeKlaro() {
    return new Promise((resolve, reject) => {
        try {
            // Check if Klaro is already loaded
            if (window.klaro) {
                resolve();
                return;
            }

            // Load Klaro
            const script = document.createElement('script');
            script.defer = true;
            script.src = 'https://cdn.kiprotect.com/klaro/v0.7.22/klaro.js';
            
            script.onload = () => {
                // Add Klaro config to window
                window.klaroConfig = {
                    ...klaroConfig,
                    noAutoLoad: false, // Allow Klaro to handle showing automatically
                };

                // Initialize Klaro
                window.klaro.setup(window.klaroConfig);
                
                resolve();
            };

            script.onerror = () => {
                reject(new Error('Failed to load Klaro'));
            };

            document.head.appendChild(script);
        } catch (error) {
            reject(error);
        }
    });
}
