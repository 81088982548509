<template>
  <div class="analysis">
    <v-container>
      <v-row class="mb-1">
        <v-col cols="12">
          <div class="d-flex align-center justify-space-between flex-wrap gap-4">
            <div class="d-none d-sm-block">
              <h1 class="text-h6 mb-2">{{ $t('analysis.title') }}</h1>
            </div>
            <div class="controls-wrapper">
              <v-btn
                color="primary"
                :icon="$vuetify.display.smAndDown"
                @click="showUploadArea = !showUploadArea"
                class="control-item"
                :disabled="isUploading"
              >
                <v-icon>mdi-upload</v-icon>
                <span v-if="!$vuetify.display.smAndDown">{{ t('analysis.uploadNew') }}</span>
              </v-btn>
              
              <v-btn
                v-if="authStore.isAuthenticated && savedAnalyses.length > 0"
                color="primary"
                :icon="$vuetify.display.smAndDown"
                @click="showSavedAnalysesDialog = true"
                class="control-item"
                :disabled="isUploading"
              >
                <v-icon>mdi-history</v-icon>
                <span v-if="!$vuetify.display.smAndDown">{{ t('analysis.savedAnalyses.load') }}</span>
              </v-btn>
              
              <v-btn
                v-if="analysis"
                color="primary"
                :icon="$vuetify.display.smAndDown"
                :disabled="!canExportPdf || !hasPremiumAccess"
                :loading="!canExportPdf"
                @click="handleExportPdf"
                class="control-item"
                v-tooltip="tooltipText" 
              >
                <v-icon>mdi-file-pdf-box</v-icon>
                <span v-if="!$vuetify.display.smAndDown">{{ t('analysis.exportPdf') }}</span>
              </v-btn>
              
              <v-btn
                color="primary"
                :icon="$vuetify.display.smAndDown"
                @click="handleBuyCredits"
                class="control-item"
                :disabled="isUploading"
              >
                <v-icon>mdi-credit-card-plus</v-icon>
                <span v-if="!$vuetify.display.smAndDown">{{ $t('analysis.buyCredits') }}</span>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-slide-y-transition>
        <div v-if="showUploadArea">
          <v-row>
            <v-col cols="12">
              <UploadArea 
                @upload-success="handleUploadSuccess" 
                @upload-state-change="handleUploadStateChange"
              />
            </v-col>
          </v-row>
        </div>
      </v-slide-y-transition>

      <template v-if="analysis">
        <v-tabs
          dark
          v-model="activeTab"
          show-arrows
          class="analysis-tabs"
          :class="{ 'tabs-pinned': isTabsPinned }"
          :slider-color="$vuetify.display.smAndDown ? undefined : 'primary'"
          :fixed-tabs="$vuetify.display.smAndDown"
          :grow="!$vuetify.display.smAndDown"
          density="compact"
        >
          <v-tab
            v-for="(tab, index) in tabs"
            :key="index"
            :value="index"
            class="tab-item"
          >
            <v-icon :icon="tab.icon" :class="{ 'mb-1': $vuetify.display.smAndDown }" />
            <span class="tab-label">{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-window v-model="activeTab" class="mt-4">
          <v-window-item :value="0">
            <v-row>
              <v-col cols="12">
                <AnalysisResults :results="results" />
              </v-col>
            </v-row>
          </v-window-item>

          <v-window-item :value="1">
            <v-row>
              <v-col cols="12">
                <RoomEvaluation :imageUrl="imageUrl" />
              </v-col>
            </v-row>
          </v-window-item>

          <v-window-item :value="2">
            <v-row>
              <v-col cols="12">
                <CostEfficiencyAnalysis :imageUrl="imageUrl" />
              </v-col>
            </v-row>
          </v-window-item>

          <v-window-item :value="3">
            <v-row>
              <v-col cols="12">
                <UserDefinedQuery class="w-100" />
              </v-col>
            </v-row>
          </v-window-item>

          <v-window-item :value="4">
            <v-row>
              <v-col cols="12">
                <ImprovementSuggestions :suggestions="suggestions" />
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </template>

      <v-row class="mt-6">
        <v-col cols="12">
          <PremiumFeatures />
        </v-col>
      </v-row>

    </v-container>

    <ContactButton />

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="4000"
      location="top"
      class="text-center"
    >
      {{ snackbar.text }}
    </v-snackbar>

    <v-dialog
      v-model="showSavedAnalysesDialog"
      max-width="600px"
    >
      <v-card>
        <v-card-title class="text-h6 text-sm-h5 text-md-h4 font-weight-bold pa-4">
          {{ t('analysis.savedAnalyses.dialogTitle') }}
        </v-card-title>
        <v-card-text class="pa-4">
          <v-list lines="two">
            <v-list-item
              v-for="analysis in savedAnalyses"
              :key="analysis.id"
              @click="handleAnalysisSelect(analysis.id)"
              :title="analysis.date"
              :subtitle="analysis.time"
              prepend-icon="mdi-file-document-outline"
            >
              <template v-slot:append>
                <v-btn
                  icon="mdi-chevron-right"
                  variant="text"
                  size="small"
                ></v-btn>
              </template>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            variant="text"
            @click="showSavedAnalysesDialog = false"
          >
            {{ t('common.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, createApp, h, watch, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import i18n from '@/plugins/i18n'
import { trackPdfExport } from '@/utils/analytics'
import AnalysisResults from '@/components/AnalysisResults.vue'
import ImprovementSuggestions from '@/components/ImprovementSuggestions.vue'
import PremiumFeatures from '@/components/PremiumFeatures.vue'
import CostEfficiencyAnalysis from '@/components/CostEfficiencyAnalysis.vue'
import RoomEvaluation from '@/components/RoomEvaluation.vue'
import html2pdf from 'html2pdf.js'
import AnalysisPdfLayout from '@/components/analysis/AnalysisPdfLayout.vue'
import { useAuthStore } from '@/stores/auth'
import ContactButton from '@/components/common/ContactButton.vue'
import axios from 'axios'
import { getIdToken } from 'firebase/auth'
import UploadArea from '@/components/upload/UploadArea.vue'
import UserDefinedQuery from '@/components/UserDefinedQuery.vue'

const store = useStore()
const { t } = useI18n()
const authStore = useAuthStore()
const { locale } = useI18n()

const snackbar = ref({
  show: false,
  text: '',
  color: 'success'
})

const showNotification = (text, color = 'success') => {
  snackbar.value = {
    show: true,
    text,
    color
  }
}

// Computed properties for data from Vuex
const analysis = computed(() => store.state.analysis.analysis)
const results = computed(() => {
  // Get the categories from the analysis state
  const categories = [
    'raumaufteilung',
    'raumfluss',
    'lichteinfall',
    'funktionalitaet',
    'stauraum',
    'privatsphaere',
    'zugaenglichkeit',
    'flexibilitaet',
    'aussenbezug'
  ]
  
  // Transform the data into the format expected by AnalysisPdfLayout
  return categories
    .filter(cat => analysis.value?.[cat])
    .map(cat => {
      const category = analysis.value[cat]
      const aspects = {}
      
      Object.entries(category).forEach(([key, value]) => {
        if (key !== 'description' && typeof value === 'object') {
          aspects[key] = {
            ...value,
            criteria: value.description?.split('\n\n')[1] || ''
          }
        }
      })
      
      return {
        name: cat,
        description: category.description,
        aspects
      }
    })
})
const suggestions = computed(() => analysis.value?.suggestions || [])
const imageUrl = computed(() => store.state.analysis.imageUrl)
const costAnalysis = computed(() => store.state.analysisCost)
const roomAnalysis = computed(() => store.state.analysisRooms)

// Add a computed property to check if all data is loaded
const canExportPdf = computed(() => {
  return !!(
    analysis.value && // Main analysis data
    store.state.analysisCost && // Cost analysis
    store.state.analysisRooms && // Room analysis
    imageUrl.value // Image URL
  )
})

// Check if user has premium access
const hasPremiumAccess = computed(() => authStore.credits > 0 || store.state.userHadPremiumWhenUploaded) 

// Tooltip text for the export button
const tooltipText = computed(() => {
  if (!authStore.isAuthenticated) {
    return t('analysis.export.loginRequired')
  }
  if (!hasPremiumAccess.value) {
    return t('analysis.export.creditsRequired')
  }
  if (!canExportPdf.value) {
    return t('analysis.export.loading')
  }
  return ''
})

// Add these refs
const savedAnalyses = ref([])

// Add this function to fetch saved analyses
const fetchSavedAnalyses = async () => {
  try {
    const token = await getIdToken(authStore.user)
    const response = await axios.get('/api/user-analyses', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    
    if (response.data.success) {
      savedAnalyses.value = response.data.data.map(analysis => {
        const date = new Date(analysis.timestamp)
        return {
          id: analysis.id,
          label: date.toLocaleString(),
          date: date.toLocaleDateString(),
          time: date.toLocaleTimeString()
        }
      })
    }
  } catch (error) {
    console.error('Failed to fetch saved analyses:', error)
    showNotification(t('analysis.savedAnalyses.fetchError'), 'error')
  }
}

// Add this with other refs
const showSavedAnalysesDialog = ref(false)

// Modify handleAnalysisSelect to close the dialog
const handleAnalysisSelect = async (analysisId) => {
  if (!analysisId) return;

  try {
    const token = await getIdToken(authStore.user);
    const response = await axios.get(`/api/analysis/${analysisId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.data.success) {
      const data = response.data.data;
      
      // Update Vuex store with the loaded data
      store.commit('setAnalysis', {
        analysis: data.analysis,
        imageUrl: data.imageUrl
      });
      store.commit('setAnalysisCost', data.costAnalysis);
      store.commit('setAnalysisRooms', data.roomAnalysis);
      store.commit('setUserIntent', data.userIntent);
      // Update lastAnalyzedImage when loading saved analysis
      store.commit('setLastAnalyzedImage', data.imageUrl);
      // allow vieweing premium content since saving was only possible with sufficient credits
      store.commit('setUserHadPremiumWhenUploaded', true);
      
      // Set saved state
      analysisSaved.value = true;
      // hide upload area
      showUploadArea.value = false;
      
      // Close the dialog after successful load
      showSavedAnalysesDialog.value = false;
      
      // Show success notification
      showNotification(t('analysis.savedAnalyses.loadSuccess'), 'success');      
    }
  } catch (error) {
    console.error('Failed to load analysis:', error);
    showNotification(t('analysis.savedAnalyses.loadError'), 'error');
  }
}

// Add this watch effect after the other watch effects
watch(
  () => authStore.isAuthenticated,
  async (isAuthenticated, wasAuthenticated) => {
    // Only fetch when changing from unauthenticated to authenticated
    if (isAuthenticated && !wasAuthenticated) {
      await fetchSavedAnalyses()
    }
  }
)

// Modify the onMounted hook
onMounted(() => {
  // Scroll to top
  window.scrollTo(0, 0)
  
  // if we got here from main page (without uploading an image), show the upload area as default
  if (!analysis.value) {
    showUploadArea.value = true
  }
  
  // If already authenticated on mount, fetch saved analyses
  if (authStore.isAuthenticated) {
    fetchSavedAnalyses()
  }
})

const handleExportPdf = async () => {
  if (!hasPremiumAccess.value) {
    return
  }
  
  // Track the export attempt
  trackPdfExport()
  
  const pdfContainer = document.createElement('div')
  
  // Create app with i18n plugin
  const vNode = h(AnalysisPdfLayout, {
    imageUrl: imageUrl.value,
    analysisCategories: results.value,
    costAnalysis: costAnalysis.value,
    roomAnalysis: roomAnalysis.value,
    suggestions: suggestions.value
  })
  
  // Create wrapper component to provide i18n
  const WrapperComponent = {
    setup() {
      return () => vNode
    }
  }

  const app = createApp(WrapperComponent)
  app.use(i18n) // Add i18n to the app instance
  
  // Mount the app to the container
  app.mount(pdfContainer)
  document.body.appendChild(pdfContainer)

  try {
    const opt = {
      margin: 10,
      filename: `floorplan-analysis-${new Date().toISOString().split('T')[0]}.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    }

    await html2pdf().set(opt).from(pdfContainer).save()
  } finally {
    // Clean up
    app.unmount()
    document.body.removeChild(pdfContainer)
  }
}

const analysisSaved = ref(false)

const canSaveAnalysis = computed(() => {
  return !!(
    analysis.value && // Main analysis data
    costAnalysis.value && // Cost analysis
    roomAnalysis.value && // Room analysis
    imageUrl.value && // Image URL
    !analysisSaved.value // Not already saved
  )
})

// Add a watch effect to trigger auto-save
watch(
  canSaveAnalysis,
  async (canSave) => {
    if (canSave && authStore.isAuthenticated && hasPremiumAccess.value) {
      try {
        const token = await getIdToken(authStore.user)
        
        await axios.post('/api/store-analysis', {
          analysis: analysis.value,
          costAnalysis: costAnalysis.value,
          roomAnalysis: roomAnalysis.value,
          imageUrl: imageUrl.value,
          userIntent: store.state.userIntent
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        
        analysisSaved.value = true;
        showNotification(t('analysis.saveAnalysis.autoSaveSuccess'), 'success');
        await fetchSavedAnalyses(); // Refresh the list
      } catch (error) {
        console.error('Failed to auto-save analysis:', error);
        showNotification(t('analysis.saveAnalysis.error'), 'error');
      }
    }
  }
);

// Add new refs
const showUploadArea = ref(false);

// Add this ref near the top of the script section with other refs
const isUploading = ref(false);

// Add handler for upload success
const handleUploadSuccess = (data) => {
  // Update store with new analysis data
  store.commit('setAnalysis', {
    analysis: data.analysis,
    imageUrl: data.imageUrl
  });
  
  // Reset lastAnalyzedImage for new upload
  store.commit('setLastAnalyzedImage', null);
  
  // Reset upload area visibility
  showUploadArea.value = false;
  
  // Reset saved state for new analysis
  analysisSaved.value = false;
  isUploading.value = false;
};

// Add this method in the script section
const handleUploadStateChange = (uploading) => {
  isUploading.value = uploading;
};

// Modify the handleBuyCredits function
const handleBuyCredits = () => {
  // Scroll to PremiumFeatures section
  const premiumSection = document.querySelector('.premium-features-card');
  if (premiumSection) {
    premiumSection.scrollIntoView({ behavior: 'smooth' });
  }
};

// Add to script setup section:
const activeTab = ref(0)

// Add a watch effect for tab changes
watch(activeTab, () => {
  // Smooth scroll to top when tab changes
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

// Add these computed properties:
const tabs = computed(() => [
  { title: t('analysis.tabs.analysis'), icon: 'mdi-clipboard-text' },
  { title: t('analysis.tabs.rooms'), icon: 'mdi-door' },
  { title: t('analysis.tabs.costs'), icon: 'mdi-currency-usd' },
  { title: t('analysis.tabs.requirements'), icon: 'mdi-clipboard-check' },
  { title: t('analysis.tabs.improvements'), icon: 'mdi-lightbulb' }
])

// Reactive variable to toggle the pinned state
const isTabsPinned = ref(false);

// Handler for pinning tabs: pin (fixed at top:0) if the page is scrolled more than 10px,
// and revert to its original sticky position when at the very top.
const handleTabsPin = () => {
  isTabsPinned.value = window.pageYOffset > 10;
};

// Attach the scroll event when the component is mounted
onMounted(() => {
  window.addEventListener('scroll', handleTabsPin);
});

// Remove the scroll event when the component is unmounted
onUnmounted(() => {
  window.removeEventListener('scroll', handleTabsPin);
});

// Modify the existing watch effect
watch(
  () => analysis.value,
  (newAnalysis) => {
    if (newAnalysis) {
      // Pre-fetch both room evaluation and cost analysis data when analysis is loaded
      store.dispatch('preloadRoomAnalysis', {
        imageUrl: imageUrl.value,
        language: locale.value
      })
      
      store.dispatch('preloadCostAnalysis', {
        imageUrl: imageUrl.value,
        language: locale.value
      })
    }
  },
  { immediate: true }
)
</script>

<style scoped>
.analysis {
  min-height: 100vh;
  background-color: var(--background);
}

/* Typography overrides */
h1 {
  color: var(--text-primary);
}

p {
  color: var(--text-secondary);
}

:deep(.v-card) {
  border-radius: 16px;
  transition: all 0.3s ease;
}

:deep(.v-card:hover) {
  box-shadow: var(--shadow-md);
}

.analysis-select {
  min-width: 300px;
  max-width: 400px;
  
  :deep(.v-field) {
    padding: 0 12px;
  }
  
  @media (max-width: 660px) {
    min-width: 100%;
  }
}

/* Add these new styles */
.d-flex.gap-2 {
  align-items: center;
}

.d-flex.gap-2 > * {
  flex-shrink: 0;
}

@media (max-width: 660px) {
  .d-flex.gap-2 {
    width: 100%;
  }
  
  .d-flex.gap-2 > * {
    flex: 1;
  }
  
  .d-flex.gap-2 .v-btn {
    width: 100%;
  }
}

.controls-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
}

.control-item {
  height: 40px;
}

@media (max-width: 660px) {
  .controls-wrapper {
    display: flex;
    gap: 4px;
    justify-content: flex-end;
  }
  
  .control-item {
    width: 36px !important;
    min-width: 36px !important;
    height: 36px !important;
    padding: 0 !important;
  }
  
  .control-item.v-btn {
    margin: 0;
  }

  /* Make icons smaller on mobile */
  .control-item .v-icon {
    font-size: 20px;
  }
}

.gap-4 {
  gap: 1rem;
}

/* Add these new styles */
:deep(.v-slide-y-transition-enter-active),
:deep(.v-slide-y-transition-leave-active) {
  transition-duration: 0.3s !important;
}

/* Add these new styles */
.preview-row {
  min-height: 300px;
}

.preview-row .v-col {
  height: 100%;
}

/* Update the tabs styles for all screen sizes */
.analysis-tabs {
  position: sticky;
  top: 68px;
  z-index: 2;
  background-color: rgb(var(--v-theme-surface));
  width: 100%;
  --v-tabs-height: 46px;  /* Override Vuetify's default tab height */
}

@media (min-width: 601px) {
  .analysis-tabs {
    top: 80px; /* Match the desktop app bar height */
  }
}

/* Add some spacing and style to the tabs */
:deep(.v-tab) {
  text-transform: none;
  font-weight: 500;
  min-width: 120px;
  padding-top: 16px;    /* Further increased vertical padding */
  padding-bottom: 16px;
  color: var(--v-theme-on-surface);
}

:deep(.v-window) {
  height: auto !important;
}

:deep(.v-window__container) {
  height: auto !important;
}

:deep(.v-window-item) {
  height: auto !important;
}

/* Add some padding to the bottom of each tab content */
:deep(.v-window-item) > * {
  padding-bottom: 24px;
}

/* Update the mobile tab styles */
@media (max-width: 600px) {
  .analysis-tabs {
    background-color: rgb(var(--v-theme-surface));
    top: 68px;
  }

  :deep(.v-tab) {
    padding: 16px 4px;
    height: 92px;  /* Further increased height */
    font-size: 11px;
    letter-spacing: -0.2px;
  }
  
  :deep(.v-tab .v-icon) {
    margin: 0 !important;
    font-size: 28px;  /* Slightly larger icons to match new height */
    height: 28px;
    width: 28px;
  }

  :deep(.tab-label) {
    line-height: 1.1;
    text-align: center;
    white-space: normal;
    display: block;
    max-width: 72px;
    font-weight: 400;
  }

  /* Make active tab more prominent but subtler */
  :deep(.v-tab--selected) {
    background-color: rgba(var(--v-theme-primary), 0.1);
    color: rgb(var(--v-theme-primary));
    border-radius: 4px;
    font-weight: 500;
  }
}

/* Add some transition effects */
:deep(.v-window-item) {
  transition: opacity 0.3s ease;
}

:deep(.v-window-item--active) {
  opacity: 1;
}

/* Add a subtle shadow to the sticky tabs */
.analysis-tabs {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* New styles for the pinned state */
.tabs-pinned {
  position: fixed !important;
  top: 0 !important;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: var(--background);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 16px; /* Add some padding when pinned */
}

@media (min-width: 601px) {
  .tabs-pinned {
    top: 24px !important;
  }
}

/* Update active/selected tab styling to be more prominent */
:deep(.v-tab--selected) {
  background-color: rgba(var(--v-theme-primary), 0.2);
  color: var(--v-theme-on-primary);
  border-radius: 4px;
  font-weight: 500;
}

</style>