<template>
  <div 
    class="description-container"
    :class="{ 'premium-item': isPremium }"
  >
    <div class="content">
      <!-- For premium content -->
      <template v-if="isPremium">
        <!-- First portion unblurred -->
        <span class="unblurred-content">
          <slot name="preview">
            <span v-html="formattedPreview"></span>
          </slot>
        </span>
        <!-- Rest of content with premium blur -->
        <span 
          class="premium-blur"
          @click="$emit('upgrade')"
        >
          <slot></slot>
        </span>
      </template>
      <!-- For non-premium content -->
      <template v-else>
        <slot></slot>
      </template>
    </div>
    <div 
      v-if="isPremium"
      class="premium-message"
      @click="$emit('upgrade')"
    >
      <div class="premium-message-content">
        <v-icon icon="mdi-lock" size="small" class="mr-2"></v-icon>
        <span class="premium-text">{{ $t('analysis.results.premiumFeature') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { formatMarkdownLite } from '@/utils/textFormatting'

const props = defineProps({
  isPremium: {
    type: Boolean,
    default: false
  },
  previewContent: {
    type: String,
    default: ''
  }
})

const formattedPreview = computed(() => {
  return formatMarkdownLite(props.previewContent)
})

defineEmits(['upgrade'])
</script>

<style scoped>
.description-container {
  position: relative;
}

.premium-blur {
  filter: blur(4px);
  user-select: none;
  cursor: pointer;
  display: inline-block;
  transition: filter 0.2s ease;
}

.premium-blur:hover {
  filter: blur(6px);
}

.premium-message {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  padding: 4px 12px;
  font-size: 0.85rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.2s ease;
  max-width: 90%;
  width: max-content;
}

.premium-message-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4px;
}

.premium-text {
  display: none;
  white-space: normal;
  text-align: center;
  line-height: 1.2;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.premium-item {
  position: relative;
}

.premium-item:hover .premium-text {
  display: inline;
}

.unblurred-content {
  user-select: text;
  filter: none;
  display: inline;
  margin-right: 4px;
}

.content {
  display: inline;
}

.unblurred-content :deep(p) {
  display: inline;
  margin: 0;
}

.unblurred-content :deep(br) {
  display: none;
}

/* Ensure other markdown elements in preview are styled properly */
.unblurred-content :deep(strong) {
  font-weight: 600;
}

.unblurred-content :deep(em) {
  font-style: italic;
  color: var(--v-primary-base);
}

.unblurred-content :deep(code) {
  background-color: var(--v-surface-variant);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
}
</style> 