import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import axios from 'axios';
import { initializeKlaro } from './plugins/consent-manager';
import { createPinia } from 'pinia';
import posthogPlugin, { initializePostHog } from "./plugins/posthog";

// Global styles
import './assets/styles/main.css';
import './assets/styles/global.css'

const app = createApp(App);
app.use(createPinia());
app.use(posthogPlugin);

// Configure axios
axios.defaults.baseURL = '';  // Use relative URLs

// Initialize PostHog first
initializePostHog();

// Then initialize consent manager and other analytics
Promise.all([
    initializeKlaro().catch(error => {
        console.error('Failed to initialize consent manager:', error);
    })
]).finally(() => {
    app
        .use(router)
        .use(store)
        .use(vuetify)
        .use(i18n)
        .use(posthogPlugin)
        .mount('#app');
});
