<template>
  <v-card class="floorplan-card d-flex flex-column">
    <v-card-title>{{ $t('analysis.preview.title') }}</v-card-title>
    <v-card-text class="floorplan-container">
      <v-img
        :src="imageUrl"
        class="floorplan-image"
        contain
      ></v-img>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const imageUrl = computed(() => store.state.analysis?.imageUrl)
</script>

<style scoped>
.floorplan-card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.floorplan-container {
  max-height: 50vh;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.floorplan-image {
  width: 100%;
  height: 100%;
  max-height: calc(50vh - 80px); /* Account for card title and padding */
  object-fit: contain;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .floorplan-container {
    max-height: 40vh;
  }
  
  .floorplan-image {
    max-height: calc(40vh - 80px);
  }
}
</style>
