import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    analysis: {
      analysis: null,
      imageUrl: null
    },
    analysisRooms: null,
    analysisCost: null,
    modelQuality: 'fast',
    userHadPremiumWhenUploaded: false,
    lastAnalyzedImage: null,
    userIntent: null,
  },
  mutations: {
    setAnalysis(state, { analysis, imageUrl }) {
      state.analysis = {
        analysis,
        imageUrl
      }
    },
    setAnalysisRooms(state, rooms) {
      state.analysisRooms = rooms
    },
    setAnalysisCost(state, cost) {
      state.analysisCost = cost
    },
    setModelQuality(state, quality) {
      state.modelQuality = quality
    },
    resetAnalysisData(state) {
      state.analysis = {
        analysis: null,
        imageUrl: null
      };
      state.analysisRooms = null;
      state.analysisCost = null;
      state.lastAnalyzedImage = null;
      state.userIntent = null;
    },
    resetState(state) {
      state.analysis = {
        analysis: null,
        imageUrl: null
      };
      state.analysisRooms = null;
      state.analysisCost = null;
      state.modelQuality = 'fast';
      state.userHadPremiumWhenUploaded = false;
    },
    setUserHadPremiumWhenUploaded(state, hadPremium) {
      state.userHadPremiumWhenUploaded = hadPremium;
    },
    setLastAnalyzedImage(state, url) {
      state.lastAnalyzedImage = url
    },
    setUserIntent(state, intent) {
      state.userIntent = intent
    }
  },
  actions: {
    async preloadRoomAnalysis({ commit, state }, { imageUrl, language }) {
      if (state.analysisRooms && state.lastAnalyzedImage === imageUrl) {
        return
      }

      try {
        const response = await axios.post('/api/analyze-barrier-free', {
          imageUrl,
          language,
          model: state.modelQuality
        })
        
        if (response.data?.data) {
          commit('setAnalysisRooms', response.data.data)
          commit('setLastAnalyzedImage', imageUrl)
        }
      } catch (error) {
        console.error('Failed to preload room analysis:', error)
      }
    },
    async preloadCostAnalysis({ commit, state }, { imageUrl, language }) {
      if (state.analysisCost && state.lastAnalyzedImage === imageUrl) {
        return
      }

      try {
        const response = await axios.post('/api/analyze-cost', {
          imageUrl,
          language,
          model: state.modelQuality
        })
        
        if (response.data?.data) {
          commit('setAnalysisCost', response.data.data)
          commit('setLastAnalyzedImage', imageUrl)
        }
      } catch (error) {
        console.error('Failed to preload cost analysis:', error)
      }
    }
  }
})
