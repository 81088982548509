//./plugins/posthog.js
import posthog from "posthog-js";

const POSTHOG_KEY = 'phc_GZKLjr1DleifwjMURmKLO71AZHQDIcEh5Ci2weeTSU0';
const POSTHOG_HOST = 'https://eu.i.posthog.com';

export function initializePostHog() {
    // Add debug logging
    const debug = false;
    
    posthog.init(POSTHOG_KEY, {
        api_host: POSTHOG_HOST,
        debug: debug,
        persistence: 'localStorage',
        capture_pageview: true,
        capture_pageleave: true,
        enable_recording: false, // Start with recording disabled
        session_recording: {
            maskAllInputs: true, // Mask all input values for privacy
            maskTextSelector: '[data-mask]', // Additional elements to mask
            recordCanvas: false, // Don't record canvas elements
            maskInputOptions: {
                password: true,
                email: true,
                number: true,
                text: true
            }
        }
    });

    // Add error handling for failed events
    posthog.onFeatureFlags(() => {
        if (debug) console.log('PostHog: Feature flags loaded');
    });

    // Debug helper
    window.posthog = posthog; // Makes it accessible in browser console
    console.log('PostHog: Initialized with debug mode');    

    return posthog;
}

// Add this to the plugin object
export function isRecordingEnabled() {
    return window.posthog && !window.posthog.has_opted_out_capturing() && 
           window.posthog._capture_metrics;
}

// Simplified Vue plugin
export default {
    install: (app) => {
        app.config.globalProperties.$posthog = posthog;
        
        // Add helpers to check status
        app.provide('posthogHelpers', {
            isRecordingEnabled,
            track: (eventName, properties = {}) => {
                try {
                    posthog.capture(eventName, properties);
                    if (process.env.NODE_ENV !== 'production') {
                        console.log('PostHog event:', eventName, properties);
                    }
                } catch (error) {
                    console.error('PostHog tracking error:', error);
                }
            }
        });
    }
};