<template>
  <div class="hero">
    <v-container class="h-100">
      <div class="hero-content text-center">
        <h1 class="hero-title font-weight-bold mb-8">
          {{ $t('hero.title.prefix') }}
          <span class="highlight">{{ $t('hero.title.highlight') }}</span>
          {{ $t('hero.title.suffix') }}
        </h1>
        <div class="features d-flex flex-column gap-4 mb-6 mx-auto">
          <div class="feature-item d-flex align-center justify-left">
            <v-icon color="primary" class="mr-4">mdi-check-circle</v-icon>
            <span>{{ $t('hero.features.forBuilders') }}</span>
          </div>
          <div class="feature-item d-flex align-center justify-left">
            <v-icon color="primary" class="mr-4">mdi-check-circle</v-icon>
            <span>{{ $t('hero.features.detailedEvaluation') }}</span>
          </div>
          <div class="feature-item d-flex align-center justify-left">
            <v-icon color="primary" class="mr-4">mdi-check-circle</v-icon>
            <span>{{ $t('hero.features.optimizationTips') }}</span>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script setup>
</script>

<style scoped>
.hero {
  min-height: 21vh;
  background: var(--background);
  padding: 4rem 0 0 0;
}

.hero-content {  
  margin: 0 auto;
  max-width: 950px;
}

.hero-title {
  font-size: 2.75rem;
  line-height: 1.3;
  letter-spacing: -0.02em;
  color: var(--text-primary);
}

.feature-item {
  color: var(--text-secondary);
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.5;
}

.feature-item .v-icon {
  color: var(--primary) !important;
}

.highlight {
  color: var(--v-primary-base);
  font-weight: 700;
}

.features {
  max-width: 450px;
}

@media (max-width: 600px) {
  .hero {
    padding: 2rem 0 0 0;
  }
  
  .hero-title {
    font-size: 1.875rem;
    line-height: 1.4;
    margin-bottom: 1.5rem;
  }

  .feature-item {
    font-size: 0.8rem;
  }

  .features {
    max-width: 100%;
    padding: 0 1rem;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .hero-title {
    font-size: 2.25rem;
    line-height: 1.35;
  }
}
</style>
