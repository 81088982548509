<template>
  <v-row id="pricing" class="pricing-section py-12">
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="4000"
      location="top"
      class="text-center"
    >
      {{ snackbar.text }}
    </v-snackbar>
    
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <h2 class="text-h6 text-sm-h5 text-md-h4 font-weight-bold">{{ $t('pricing.title') }}</h2>
          <p class="text-subtitle-2 text-sm-subtitle-1 text-md-body-1 mt-2">{{ $t('pricing.subtitle') }}</p>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-4">
        <v-col
          v-for="(plan, index) in plans"
          :key="index"
          cols="12"
          sm="10"
          md="6"
          lg="5"
          class="d-flex"
        >
          <v-card 
            class="price-card d-flex flex-column"
            :class="{ 'popular': plan.popular }"
            :data-popular-text="$t('pricing.popular_badge')"
            elevation="2"
            width="100%"
          >
            <v-card-item class="card-content">
              <div class="text-center">
                <h3 class="text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold mb-4">{{ $t(plan.nameKey) }}</h3>
                <div class="price-container mb-2">
                  <template v-if="plan.originalPrice">
                    <span class="original-price text-subtitle-1 text-sm-h6 text-md-h5 font-weight-regular text-decoration-line-through mr-2">{{ plan.originalPrice }}</span>
                    <span class="text-h5 text-sm-h4 text-md-h3 font-weight-bold">{{ plan.price }}</span>
                  </template>
                  <template v-else>
                    <span class="text-h5 text-sm-h4 text-md-h3 font-weight-bold">{{ plan.price }}</span>
                  </template>
                  <span class="text-subtitle-1 text-sm-h6 text-md-h5 ml-1">{{ $t('pricing.currency') }}</span>
                </div>
                <div class="text-subtitle-2 text-sm-subtitle-1 text-md-body-1 mb-6">{{ $t(plan.billingKey, { credits: plan.credits }) }}</div>
              </div>

              <v-divider class="mb-6"></v-divider>

              <v-list 
                density="compact" 
                class="bg-transparent pa-0"
              >
                <v-list-item
                  v-for="(feature, fIndex) in plan.features"
                  :key="fIndex"
                  class="px-0 mb-1"
                  density="compact"
                >
                  <template v-slot:prepend>
                    <v-icon 
                      :color="feature.included ? 'success' : 'grey'" 
                      size="small" 
                      class="mr-2"
                    >
                      {{ feature.included ? 'mdi-check-circle' : 'mdi-circle-outline' }}
                    </v-icon>
                  </template>
                  <v-list-item-title 
                    :class="{ 'text-medium-emphasis': !feature.included }"
                    class="text-subtitle-2 text-sm-subtitle-1 text-md-body-1 text-wrap"
                    style="white-space: normal; line-height: 1.4"
                  >
                    {{ $t(feature.textKey, { credits: plan.credits }) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-item>

            <v-card-actions class="mt-auto pa-4">
              <v-btn
                block
                :color="plan.popular ? 'primary' : 'default'"
                :variant="plan.popular ? 'flat' : 'outlined'"
                size="large"
                @click="handlePlanAction(plan)"
              >
                {{ $t(plan.buttonTextKey) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <PayPalButton
      v-model="showPayPal"
      @payment-success="handlePaymentSuccess"
      @payment-error="handlePaymentError"
    />
  </v-row>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useI18n } from 'vue-i18n';
import PayPalButton from '@/components/common/PayPalButton.vue';
import { getIdToken } from 'firebase/auth';
import { useRouter } from 'vue-router';
import { trackEvent } from '@/utils/analytics';

const { t, locale } = useI18n();

const showPayPal = ref(false);
const authStore = useAuthStore();
const snackbar = ref({
  show: false,
  text: '',
  color: 'success'
});

const pricingConfig = ref(null);
const router = useRouter();

// Add watch for auth state changes
watch(
  () => authStore.user,
  async () => {
    try {
      let headers = {};
      if (authStore.isAuthenticated) {
        const token = await getIdToken(authStore.user);
        headers.Authorization = `Bearer ${token}`;
      }

      const response = await fetch(`/api/pricing?locale=${locale.value}`, { headers });
      pricingConfig.value = await response.json();
      initializePlans();
    } catch (error) {
      console.error('Failed to fetch pricing:', error);
    }
  }
);

onMounted(async () => {
  try {
    let headers = {};
    if (authStore.isAuthenticated) {
      const token = await getIdToken(authStore.user);
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(`/api/pricing?locale=${locale.value}`, { headers });
    pricingConfig.value = await response.json();
    initializePlans();
  } catch (error) {
    console.error('Failed to fetch pricing:', error);
  }
});

const initializePlans = () => {
  if (!pricingConfig.value) return;

  plans.value = [
    {
      nameKey: 'pricing.plans.basic.name',
      price: '0',
      billingKey: 'pricing.free',
      buttonTextKey: 'pricing.start_now',
      features: [
        { textKey: 'pricing.plans.basic.features.free_analysis', included: true },
        { textKey: 'pricing.plans.basic.features.basic_ai', included: true },
        { textKey: 'pricing.plans.basic.features.basic_recommendations', included: true },
      ]
    },
    {
      nameKey: 'pricing.plans.credits.name',
      price: pricingConfig.value.discountedPrice.toFixed(2).replace('.', ','),
      originalPrice: pricingConfig.value.regularPrice.toFixed(2).replace('.', ','),
      billingKey: 'pricing.plans.credits.billing',
      popular: true,
      buttonTextKey: 'pricing.buy_credits',
      credits: pricingConfig.value.credits,
      features: [
        { textKey: 'pricing.plans.credits.features.included_analysis', included: true },
        { textKey: 'pricing.plans.credits.features.advanced_ai', included: true },      
        { textKey: 'pricing.plans.basic.features.detailed_recommendations', included: true },
        { textKey: 'pricing.plans.credits.features.cost_efficiency', included: true },
        { textKey: 'pricing.plans.credits.features.room_analysis', included: true },
        { textKey: 'pricing.plans.basic.features.export', included: true },
        { textKey: 'pricing.plans.basic.features.support', included: true }
      ]
    }
  ];
};

const handlePlanAction = (plan) => {
  if (plan.price === '0') {
    scrollToTop();
    return;
  }
  
  if (!authStore.isAuthenticated) {
    router.push({
      path: '/sign-up',
      query: { redirect: 'pricing' }
    });
    return;
  }
  
  // Track PayPal button click
  trackEvent('paypal_button_click', {
    plan_type: plan.nameKey,
    price: plan.price,
    credits: plan.credits
  });
  showPayPal.value = true;
};

const handlePaymentSuccess = () => {
  showNotification(t('pricing.payment.success'), 'success');
  authStore.fetchCredits();
};

const handlePaymentError = (error) => {
  console.error('Payment failed:', error);
  showNotification(t('pricing.payment.error'), 'error');
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const showNotification = (text, color = 'success') => {
  snackbar.value = {
    show: true,
    text,
    color
  };
};

const plans = ref([]);
</script>

<style scoped>
.pricing-section {
  padding: 4rem 0;
  background: var(--background);
}

@media (max-width: 600px) {
  .pricing-section {
    padding: 2rem 0;
  }
}

.price-card {
  height: 100%;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid var(--border-color);
  padding: 1rem;
  background: white;
}

.card-content {
  padding: 1rem;
}

.price-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.price-card.popular {
  border: 2px solid var(--primary);
  position: relative;
}

.price-card.popular::before {
  content: attr(data-popular-text);
  position: absolute;
  top: 8px;
  right: 8px;
  background: var(--primary);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 4px;
  color: var(--primary);
}

.popular .price-container {
  color: var(--primary-light);
}

.text-wrap {
  word-break: break-word;
  color: var(--text-primary);
}

.text-medium-emphasis {
  color: var(--text-secondary) !important;
}

:deep(.v-btn) {
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0;
  border-radius: 8px;
  padding: 8px 24px;
  height: 48px;
}

:deep(.v-btn.v-btn--variant-flat) {
  background: white;
  color: var(--primary);
}

:deep(.v-btn.v-btn--variant-outlined) {
  border-color: var(--primary);
  color: var(--primary);
}

.paypal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.paypal-modal {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
  position: relative;
}

.close-button {
  position: absolute;
  top: 8px;
  right: 8px;
}

.paypal-buttons-container {
  display: none;
}

.original-price {
  color: var(--text-secondary);
  font-size: 1.25rem;
  opacity: 0.75;
}

@media (max-width: 600px) {
  .price-card {
    padding: 0.5rem;
  }
  
  .card-content {
    padding: 0.5rem;
  }

  .v-card-actions {
    padding: 0.5rem !important;
  }

  .mb-6 {
    margin-bottom: 1rem !important;
  }

  .mb-4 {
    margin-bottom: 0.5rem !important;
  }
}
</style>
