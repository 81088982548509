<template>

    <v-card class="cost-analysis pa-4">
      <v-card-title class="text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold">
        {{ $t('analysis.costEfficiency.title') }}
      </v-card-title>

      <template v-if="loading">
        <div class="d-flex flex-column align-center ma-4">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mb-4"
          ></v-progress-circular>
          <span class="text-subtitle-2 text-sm-subtitle-1 text-medium-emphasis">
            {{ $t('analysis.costEfficiency.loading') }}
          </span>
        </div>        
      </template>

      <template v-else-if="error">
        <div class="text-center error--text">
          {{ error }}
          <br />
          <v-btn
            color="primary"
            variant="text"
            class="mt-2"
            :loading="loading"
            @click="fetchCostAnalysis"
          >
            <v-icon icon="mdi-refresh" class="mr-2" />
            {{ $t('common.retry') }}
          </v-btn>
        </div>
      </template>
      
      <template v-else-if="analysis">
        <!-- Summary -->
        <v-card-text class="analysis-summary mt-4" :class="{ 'premium-item': !hasPremiumAccess }">
          <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold mb-2">
            {{ $t('analysis.costEfficiency.sections.summary.title') }}
          </div>
          <PremiumContent 
            :is-premium="!hasPremiumAccess"
            :preview-content="analysis.summary.substring(0, 40)"
            @upgrade="handlePremiumContent('summary')"
          >
            <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap" 
                  v-html="formatMarkdownLite(analysis.summary)">
            </span>
          </PremiumContent>
        </v-card-text>

        <!-- Climate Resilience - replacing Space Efficiency -->
        <v-card-text class="analysis-section">
          <div class="d-flex justify-space-between align-center mb-2">
            <h3 class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">
              {{ $t('analysis.costEfficiency.sections.climateResilience.title') }}
            </h3>
            <v-rating
              :model-value="analysis.climateResilience.score"
              readonly
              density="compact"
              color="primary"
              length="5"
            ></v-rating>
          </div>
          <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap" 
                v-html="formatMarkdownLite(analysis.climateResilience.description)">
          </span>
        </v-card-text>

        <!-- Construction Cost -->
        <v-card-text class="analysis-section" :class="{ 'premium-item': !hasPremiumAccess }">
          <div class="d-flex justify-space-between align-center mb-2">
            <h3 class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">
              {{ $t('analysis.costEfficiency.sections.constructionCost.title') }}
            </h3>
            <v-rating
              :model-value="analysis.constructionCost.score"
              readonly
              density="compact"
              color="primary"
              length="5"
            ></v-rating>
          </div>
          <PremiumContent 
            :is-premium="!hasPremiumAccess"
            :preview-content="analysis.constructionCost.description.substring(0, 80)"
            @upgrade="handlePremiumContent('construction_cost')"
          >
            <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap" 
                  v-html="formatMarkdownLite(analysis.constructionCost.description)">
            </span>
          </PremiumContent>
        </v-card-text>

        <!-- Energy Efficiency -->
        <v-card-text class="analysis-section" :class="{ 'premium-item': !hasPremiumAccess }">
          <div class="d-flex justify-space-between align-center mb-2">
            <h3 class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">
              {{ $t('analysis.costEfficiency.sections.energyEfficiency.title') }}
            </h3>
            <v-rating
              :model-value="analysis.energyEfficiency.score"
              readonly
              density="compact" 
              color="primary" 
              length="5"
            ></v-rating>
          </div>
          <PremiumContent 
            :is-premium="!hasPremiumAccess"
            :preview-content="analysis.energyEfficiency.description.substring(0, 80)"
            @upgrade="handlePremiumContent('energy_efficiency')"
          >
            <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap" 
                  v-html="formatMarkdownLite(analysis.energyEfficiency.description)">
            </span>
          </PremiumContent>
        </v-card-text>
      </template>
    </v-card>

</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/auth'
import PremiumContent from '@/components/common/PremiumContent.vue'
import { trackCostEfficiencyPremiumClick } from '@/utils/analytics'
import { formatMarkdownLite } from '@/utils/textFormatting'
import { getIdToken } from 'firebase/auth'
import { useStore } from 'vuex'

const { t, locale } = useI18n()
const authStore = useAuthStore()
const store = useStore()

// Compute whether user has access to premium content
const hasPremiumAccess = computed(() => store.state.userHadPremiumWhenUploaded)

const props = defineProps({
  imageUrl: {
    type: String,
    required: true
  }
})

const loading = ref(false)
const error = ref(null)
const analysis = computed(() => store.state.analysisCost || {
  climateResilience: { score: 0, description: '' },
  constructionCost: { score: 0, description: '' },
  energyEfficiency: { score: 0, description: '' },
  summary: ''
})

const fetchCostAnalysis = async () => {
  if (loading.value) {
    return;
  }

  // If we already have analysis data in store and the imageUrl hasn't changed, use that
  if (store.state.analysisCost && store.state.lastAnalyzedImage === props.imageUrl) {
    loading.value = false;
    error.value = null;
    return;
  }

  // Reset state when starting new analysis
  store.commit('setAnalysisCost', null)
  loading.value = true
  error.value = null
  
  try {
    let headers = {}
    if (authStore.isAuthenticated) {
      const token = await getIdToken(authStore.user)
      headers.Authorization = `Bearer ${token}`
    }

    const response = await axios.post('/api/analyze-cost', {
      imageUrl: props.imageUrl,
      language: locale.value,
      model: store.state.modelQuality
    }, { headers })
    
    if (response.data?.data) {
      store.commit('setAnalysisCost', response.data.data)
      store.commit('setLastAnalyzedImage', props.imageUrl)
      await authStore.fetchCredits()
    } else {
      throw new Error('analysis.costEfficiency.error.invalidResponse')
    }
  } catch (err) {
    error.value = err.message === 'analysis.costEfficiency.error.invalidResponse' 
      ? t('analysis.costEfficiency.error.invalidResponse')
      : t('analysis.costEfficiency.error.default')
    console.error('CostEfficiencyAnalysis: Analysis failed:', err)
  } finally {
    loading.value = false
  }
}

// Update onMounted to check for preloaded data
onMounted(() => {
  if (props.imageUrl) {
    if (!store.state.analysisCost) {
      fetchCostAnalysis()
    } else {
      // Use preloaded data
      loading.value = false
      error.value = null
    }
  }
})

// Add navigation helper
const navigateToPricing = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
}

// Add premium content handler
const handlePremiumContent = (section) => {
  trackCostEfficiencyPremiumClick(section)
  
  if (!hasPremiumAccess.value) {
    navigateToPricing()
  }
}
</script>

<style scoped>
.cost-analysis {
  border-radius: 8px;
}

.analysis-section {
  border-bottom: 1px solid var(--v-border-color);
  padding-top: 16px;
  padding-bottom: 16px;
}

.analysis-section:last-child {
  border-bottom: none;
}

.analysis-summary {
  background-color: var(--v-surface-variant);
  border-radius: 4px;
  position: relative;
}

.analysis-description {
  white-space: normal !important;
  overflow: visible;
  text-overflow: unset;
  word-wrap: break-word;
}

/* Standardize text styles */
.analysis-description :deep(p) {
  margin: 0.75rem 0;
  padding: 0.25rem 0;
  line-height: 1.6;
}

.analysis-description :deep(ul),
.analysis-description :deep(ol) {
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
}

.analysis-description :deep(li) {
  margin-bottom: 0.25rem;
}

.analysis-description :deep(code) {
  background-color: var(--v-surface-variant);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
}

.analysis-description :deep(blockquote) {
  border-left: 3px solid var(--v-primary-base);
  margin: 0.75rem 0;
  padding-left: 1rem;
  color: var(--text-secondary);
}

.analysis-description :deep(a) {
  color: var(--v-primary-base);
  text-decoration: none;
}

.analysis-description :deep(a:hover) {
  text-decoration: underline;
}

.analysis-description :deep(h1),
.analysis-description :deep(h2),
.analysis-description :deep(h3) {
  color: var(--text-primary);
  font-weight: 500;
  line-height: 1.2;
}

/* Remove custom typography styles and let Vuetify handle it */
:deep(.text-medium-emphasis) {
  opacity: 0.7;
}

.premium-item {
  position: relative;
}

.premium-item:hover .premium-message {
  display: flex;
}

/* Add styles for formatted text */
:deep(strong) {
  font-weight: 600;
}

:deep(em) {
  font-style: italic;
  color: var(--v-primary-base);
}

/* Typography and text styles */
.analysis-description {
  white-space: normal !important;
  overflow: visible;
  text-overflow: unset;
  word-wrap: break-word;
}

/* Remove custom typography styles and let Vuetify handle it */
:deep(.v-card-title) {
  font-size: inherit;
  font-weight: inherit;
}

/* Rest of existing styles... */
</style>

