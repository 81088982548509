<template>
  <v-card class="analysis-card">
    <v-card-title class="text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold">
      {{ $t('analysis.results.title') }}
    </v-card-title>
    <v-card-text class="analysis-container">
      <template v-if="analysisCategories.length > 0">
        <!-- FloorplanPreview and General Impression side by side -->
        <v-row class="mb-4">
          <v-col cols="12" sm="12" md="6">
            <FloorplanPreview />
          </v-col>
          
          <v-col cols="12" sm="12" md="6" v-if="analysis.genereller_eindruck">
            <v-card class="h-100">
              <v-card-item>
                <div class="d-flex align-center mb-4">
                  <v-icon icon="mdi-home-search" color="primary" class="analysis-icon"></v-icon>
                  <div class="text-subtitle-1 text-sm-h6 text-md-h6 font-weight-bold">
                    {{ $t('analysis.results.generalImpression') }}
                  </div>
                </div>
                <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap">
                  {{ analysis.genereller_eindruck }}
                </div>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col 
            v-for="category in analysisCategories"
            :key="category.name"
            cols="12"
            sm="12"
            md="6"
            class="d-flex"
          >
            <v-card class="category-card flex-grow-1">
              <v-card-item>
                <!-- Category Header -->
                <div class="d-flex align-center justify-space-between mb-4">
                  <div class="d-flex align-center">
                    <v-icon :icon="getIconForCategory(category.name)" color="primary" class="analysis-icon"></v-icon>
                    <div class="text-subtitle-1 text-sm-h6 text-md-h6 font-weight-bold">{{ $t(`analysis.results.categories.${category.name}`) }}</div>
                  </div>
                  <v-rating
                    :model-value="calculateCategoryScore(category)"
                    readonly
                    density="compact"
                    color="primary"
                    length="5"
                  ></v-rating>
                </div>

                <!-- Category Description -->
                <div class="mb-4">
                  <PremiumContent 
                    :is-premium="isPremiumCategory(category.name)"
                    :preview-content="category.description.substring(0, 20)"
                    @upgrade="navigateToPricing"
                  >
                    <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap">
                      {{ category.description }}
                    </span>
                  </PremiumContent>
                </div>

                <!-- Category Aspects -->
                <v-list>
                  <v-list-item
                    v-for="(aspect, key) in category.aspects"
                    :key="key"
                    class="analysis-item mb-4"
                    :class="{ 'premium-item': isPremiumCategory(category.name) }"
                    @click="isPremiumCategory(category.name) && handlePremiumContent(category.name)"
                  >
                    <div>
                      <v-list-item-title class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold mb-2">
                        {{ $t(`analysis.results.aspects.${key}`) }}
                        <v-chip
                          :color="getRatingColor(aspect.rating)"
                          size="small"
                          class="ml-2"
                        >
                          {{ $t(`analysis.results.ratings.${aspect.rating}`) }}
                        </v-chip>
                      </v-list-item-title>
                      <PremiumContent 
                        :is-premium="isPremiumCategory(category.name)"
                        :preview-content="aspect.description.substring(0, 20)"
                        @upgrade="navigateToPricing"
                      >
                        <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap" 
                              v-html="formatMarkdownLite(aspect.description)">
                        </span>
                      </PremiumContent>
                    </div>
                  </v-list-item>
                </v-list>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>
      </template>
      
      <div v-else class="text-center pa-4">
        {{ $t('analysis.results.noResults') }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import PremiumContent from '@/components/common/PremiumContent.vue'
import FloorplanPreview from '@/components/FloorplanPreview.vue'
import { trackPremiumUpsellClick } from '@/utils/analytics'
import { formatMarkdownLite } from '@/utils/textFormatting'

const store = useStore()
const analysis = computed(() => store.state.analysis?.analysis || {})

// Compute whether user has access to premium content
const hasPremiumAccess = computed(() => store.state.userHadPremiumWhenUploaded)

// Update the isPremiumCategory function to use the new logic
const isPremiumCategory = (category) => {
  if (hasPremiumAccess.value) return false
  return PREMIUM_CATEGORIES.includes(category)
}

// Calculate the average score for a category based on its aspects
const calculateCategoryScore = (category) => {
  if (!category.aspects || Object.keys(category.aspects).length === 0) return 0
  
  const ratings = Object.values(category.aspects).map(aspect => {
    // Convert text ratings to numeric scores (1-5 scale)
    switch(aspect.rating.toLowerCase()) {
      case 'positive': return 5
      case 'neutral': return 3
      case 'negative': return 1
      default: return 0
    }
  })
  
  const average = ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length
  return Math.round(average * 2) / 2
}

const analysisCategories = computed(() => {
  const categories = [
    'raumaufteilung',
    'raumfluss',
    'lichteinfall',
    'funktionalitaet',
    'stauraum',
    'privatsphaere',
    'zugaenglichkeit',
    'flexibilitaet',
    'aussenbezug'
  ]
  
  return categories
    .filter(cat => analysis.value[cat])
    .map(cat => {
      const category = analysis.value[cat]
      const aspects = {}
      
      Object.entries(category).forEach(([key, value]) => {
        if (key !== 'description' && typeof value === 'object') {
          aspects[key] = {
            ...value,
            criteria: extractCriteriaFromDescription(value.description)
          }
        }
      })
      
      return {
        name: cat,
        description: category.description,
        aspects
      }
    })
})

const extractCriteriaFromDescription = (description) => {
  if (!description) return ''
  const parts = description.split('\n\n')
  return parts[1] || ''
}

const getIconForCategory = (category) => {
  const categoryIcons = {
    raumaufteilung: 'mdi-floor-plan',
    raumfluss: 'mdi-routes',
    lichteinfall: 'mdi-lightbulb',
    funktionalitaet: 'mdi-lightning-bolt',
    stauraum: 'mdi-package-variant-closed',
    privatsphaere: 'mdi-shield-account',
    zugaenglichkeit: 'mdi-account-outline',
    flexibilitaet: 'mdi-sync',
    aussenbezug: 'mdi-nature',
    default: 'mdi-home'
  }
  return categoryIcons[category] || categoryIcons.default
}

const getRatingColor = (rating) => {
  const colors = {
    positive: 'success',
    positiv: 'success',
    neutral: 'warning',
    negative: 'error',
    negativ: 'error'
  }
  return colors[rating] || 'info'
}

// Add this constant for premium categories
const PREMIUM_CATEGORIES = [
  'funktionalitaet',
  'stauraum',
  'privatsphaere',
  'zugaenglichkeit',
  'flexibilitaet',
  'aussenbezug',
  'raumfluss',
  'lichteinfall',
]

// Keep these functions as they're still needed:
const handlePremiumContent = (category) => {
  trackPremiumUpsellClick(category)
  
  if (!hasPremiumAccess.value) {
    navigateToPricing()
  }
}

const navigateToPricing = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
}
</script>

<style scoped>
.analysis-container {
  max-height: none;
  overflow-y: visible;
}

.category-card {
  background-color: var(--v-background);
  border: 1px solid rgba(var(--v-border-color), 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  height: 100%;
}

.category-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.analysis-item {
  margin-bottom: 16px;
  transition: all 0.3s ease;
  padding: 2px;
  border-radius: 4px;
}

.analysis-item:hover {
  background-color: var(--background);
}

.analysis-icon {
  margin-right: 12px;
  font-size: 24px;
}

.analysis-description {
  white-space: normal !important;
  overflow: visible;
  text-overflow: unset;
  word-wrap: break-word;
}

.category-description {
  font-style: italic;
  padding: 8px 0;
}

/* Premium content styles */
.premium-item {
  position: relative;
}

.premium-message {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  padding: 4px 8px;
  font-size: 0.85rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  pointer-events: auto;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.premium-item:hover .premium-message {
  display: flex;
}

/* Responsive grid spacing */
:deep(.v-row) {
  margin: 0 -8px;
}

:deep(.v-col) {
  padding: 8px;
}

/* Card content padding */
:deep(.v-card-item) {
  padding: 14px;
}

.general-impression-text {
  white-space: normal;
  line-height: 1.6;
}

/* Remove custom typography styles and let Vuetify handle it */
:deep(.v-list-item-subtitle) {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: unset !important;
  -webkit-line-clamp: unset !important;
}

/* Update typography classes */
.analysis-container {
  max-height: none;
  overflow-y: visible;
}

.category-card {
  background-color: var(--v-background);
  border: 1px solid rgba(var(--v-border-color), 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  height: 100%;
}

/* Remove any custom typography styles and let Vuetify handle it */
:deep(.v-card-title) {
  font-size: inherit;
  font-weight: inherit;
}
</style>
