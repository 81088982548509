<template>
  <div class="contact-button-container">
    <!-- Floating Button -->
    <v-btn
      color="primary"
      :icon="!showExpandedButton"
      :size="$vuetify.display.smAndDown ? 'small' : $vuetify.display.mdAndDown ? 'default' : 'large'"
      class="contact-fab"
      elevation="4"
      @click="showDialog = true"
      :class="{ 'expanded': showExpandedButton }"
    >
      <v-icon :size="$vuetify.display.smAndDown ? 'small' : $vuetify.display.mdAndDown ? 'default' : 'large'">
        mdi-message-text
      </v-icon>
      <span v-if="showExpandedButton" class="ms-2">{{ $t('contact.feedback_prompt') }}</span>
      <v-tooltip activator="parent" location="top">
        {{ $t('contact.button') }}
      </v-tooltip>
    </v-btn>

    <!-- Contact Dialog -->
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card class="contact-dialog">
        <v-card-title class="d-flex justify-space-between align-center">
          {{ $t('contact.title') }}
          <v-btn icon="mdi-close" variant="text" @click="showDialog = false"></v-btn>
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="isFormValid" @submit.prevent="submitForm">
            <v-text-field
              v-model="formData.email"
              :label="$t('contact.form.email')"
              :rules="emailRules"
              type="email"
              required
              variant="outlined"
              density="comfortable"
              :disabled="!!authStore.user"
              class="mb-4"
            ></v-text-field>

            <v-textarea
              v-model="formData.message"
              :label="$t('contact.form.message')"
              :rules="[v => !!v || $t('contact.form.required')]"
              required
              variant="outlined"
              auto-grow
              rows="4"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="!isFormValid || loading"
            @click="submitForm"
          >
            {{ $t('contact.form.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Success/Error Snackbar -->
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="4000"
      location="top"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/auth'
import { useStore } from 'vuex'
import axios from 'axios'

const { t } = useI18n()
const authStore = useAuthStore()
const store = useStore()
const showDialog = ref(false)
const isFormValid = ref(false)
const loading = ref(false)
const form = ref(null)
const showExpandedButton = ref(false)

// Check if there's an active analysis
const hasActiveAnalysis = computed(() => !!store.state.analysis.analysis)

// Watch for both timing and analysis state
watch([hasActiveAnalysis], ([hasAnalysis]) => {
  if (hasAnalysis) {
    // Only start the timer if we have an analysis
    setTimeout(() => {
      showExpandedButton.value = true
    }, 15000)
  } else {
    // Reset when no analysis is present
    showExpandedButton.value = false
  }
})

const formData = reactive({
  email: authStore.user?.email || '',
  message: ''
})

// Update email when auth state changes
watch(
  () => authStore.user?.email,
  (newEmail) => {
    if (newEmail) {
      formData.email = newEmail
    }
  }
)

const snackbar = reactive({
  show: false,
  text: '',
  color: 'success'
})

const emailRules = [
  v => !!v || t('contact.form.required'),
  v => /.+@.+\..+/.test(v) || t('contact.form.invalidEmail')
]

const showNotification = (text, color = 'success') => {
  snackbar.show = true
  snackbar.text = text
  snackbar.color = color
}

const submitForm = async () => {
  if (!isFormValid.value) return

  loading.value = true
  try {
    await axios.post('/api/contact', formData)
    showDialog.value = false
    showNotification(t('contact.success'))
    formData.email = ''
    formData.message = ''
  } catch (error) {
    console.error('Contact form submission error:', error)
    showNotification(t('contact.error'), 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped>
.contact-button-container {
  position: fixed;
  z-index: 100;
  bottom: 24px;
  right: 24px;
}

.contact-fab {
  transition: all 0.3s ease;
}

.contact-fab.expanded {
  min-width: 200px;
  padding-right: 16px;
  padding-left: 12px;
}

/* Medium screens */
@media (max-width: 960px) {
  .contact-button-container {
    bottom: 20px;
    right: 20px;
  }
  
  .contact-fab.expanded {
    min-width: 180px;
  }
}

/* Small screens (mobile) */
@media (max-width: 600px) {
  .contact-button-container {
    bottom: 16px;
    right: 16px;
  }
  
  .contact-fab.expanded {
    min-width: 160px;
  }
}

.contact-fab:hover {
  transform: scale(1.1);
}

.contact-dialog {
  border-radius: 12px;
}
</style> 