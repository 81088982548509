<template>
  <v-dialog
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    persistent
    max-width="500"
    class="upload-feedback-modal"
  >
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between">
        {{ $t('upload.feedback.title') }}
        <v-btn
          icon="mdi-close"
          variant="text"
          size="small"
          @click="handleSkip"
          :disabled="loading"
        />
      </v-card-title>

      <v-card-text>
        <p class="text-body-1 mb-4">{{ $t('upload.feedback.description') }}</p>
        
        <v-form v-model="isValid" @submit.prevent="handleSubmit">
          <!-- Expectations -->
          <v-textarea
            v-model="feedback.expectations"
            :label="$t('upload.feedback.expectations.label')"
            :placeholder="$t('upload.feedback.expectations.placeholder')"
            :hint="$t('upload.feedback.expectations.hint')"
            rows="3"
            class="mb-4"
            :disabled="loading"
            :rules="[v => !!v || $t('upload.feedback.required')]"
          ></v-textarea>

          <!-- Usage Purpose -->
          <v-select
            v-model="feedback.purpose"
            :items="purposeOptions"
            :label="$t('upload.feedback.purpose.label')"
            :hint="$t('upload.feedback.purpose.hint')"
            class="mb-4"
            :disabled="loading"
            :rules="[v => !!v || $t('upload.feedback.required')]"
          ></v-select>

          <!-- Privacy Policy Consent -->
          <p class="text-body-2 text-grey-darken-1">
            {{ $t('upload.feedback.privacy.text') }}
            <router-link to="/datenschutz" target="_blank">
              {{ $t('upload.feedback.privacy.link') }}
            </router-link>
          </p>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="handleSubmit"
          :loading="loading"
          :disabled="!isValid || loading"
        >
          {{ $t('upload.feedback.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { trackUploadFeedback } from '@/utils/analytics'
import axios from 'axios'

const { t } = useI18n()

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  filename: {
    type: String,
    required: false,
    default: ''
  }
})

const emit = defineEmits(['update:modelValue', 'submit', 'skip'])

const loading = ref(false)
const isValid = ref(false)

const feedback = ref({
  expectations: '',
  purpose: '',
})

const purposeOptions = computed(() => [
  { title: t('upload.feedback.purpose.personal'), value: 'personal' },
  { title: t('upload.feedback.purpose.professional'), value: 'professional' },
  { title: t('upload.feedback.purpose.client'), value: 'client' },
  { title: t('upload.feedback.purpose.other'), value: 'other' }
])

const handleSubmit = async () => {
  if (!isValid.value) return
  
  loading.value = true
  try {
    // Send feedback to backend with filename (only if it exists)
    await axios.post('/api/feedback', {
      ...feedback.value,
      ...(props.filename ? { filename: props.filename } : {})
    })
    
    // Track feedback submission
    trackUploadFeedback({
      ...feedback.value,
      ...(props.filename ? { filename: props.filename } : {}),
      skipped: false
    })
    
    emit('submit', feedback.value)
  } catch (error) {
    console.error('Failed to store feedback:', error)
  } finally {
    loading.value = false
  }
}

const handleSkip = () => {
  trackUploadFeedback({ skipped: true })
  emit('skip')
}
</script>

<style scoped>
.upload-feedback-modal {
  backdrop-filter: blur(4px);
}

:deep(.v-card) {
  border-radius: 12px;
}

:deep(.v-card-title) {
  padding: 16px 24px;
  font-size: 1.25rem;
  font-weight: 600;
}

:deep(.v-card-text) {
  padding: 24px;
  padding-top: 0;
}

@media (max-width: 600px) {
  :deep(.v-card-title) {
    font-size: 1.0rem;
  }

  :deep(.v-card-text) {
    font-size: 0.95rem;
  }

  :deep(.v-btn) {
    font-size: 0.9rem;
  }

  :deep(.v-label),
  :deep(.v-field__input),
  :deep(.v-select__selection),
  :deep(.v-textarea textarea),
  :deep(.v-messages),
  :deep(.v-field__hint) {
    font-size: 0.8rem !important;
  }

  :deep(.text-body-1) {
    font-size: 0.8rem !important;
  }
}
</style> 