// Google Analytics event tracking utility functions

export const isAnalyticsEnabled = () => {
  // Check each condition separately for better debugging
  const isEnabledInEnv = process.env.VUE_APP_GA_ENABLED === 'true';
  const isGtagDefined = typeof window.gtag === 'function';
  return isEnabledInEnv && isGtagDefined;
};

export const trackEvent = (eventName, eventParams = {}) => {
  if (isAnalyticsEnabled()) {
    window.gtag('event', eventName, {
      ...eventParams,
      non_interaction: false
    });
  }
};

// Pre-defined event tracking functions
export const trackImageUpload = (fileSize, status = 'attempt', filename = '') => {
  trackEvent('image_upload', {
    file_size: fileSize,
    status: status, // 'attempt', 'success', or 'failed'
    filename: filename
  });
};

export const trackSignIn = (success = true) => {
  trackEvent('sign_in', {
    status: success ? 'success' : 'failed'
  });
};

export const trackSignUp = (success = true) => {
  trackEvent('sign_up', {
    status: success ? 'success' : 'failed'
  });
};

// Add new tracking function for feature demo interactions
export const trackFeatureDemo = (action, featureTitle) => {
  trackEvent('feature_demo_interaction', {
    action: action, // 'view', 'next', 'previous'
    feature: featureTitle
  });
};

// Add specific analysis event tracking functions
export const trackAnalysisCategoryView = (categoryName, score) => {
  trackEvent('analysis_category_expanded', {
    category_name: categoryName,
    category_score: score
  });
};

export const trackPremiumUpsellClick = (categoryName) => {
  trackEvent('analysis_premium_click', {
    category_name: categoryName
  });
};

// Simplify room evaluation tracking to just expansion and premium clicks
export const trackRoomExpanded = (roomName, overallRating) => {
  trackEvent('room_expanded', {
    room_name: roomName,
    overall_rating: overallRating
  });
};

export const trackRoomPremiumClick = (roomName) => {
  trackEvent('room_premium_click', {
    room_name: roomName
  });
};

// Add improvement suggestion tracking
export const trackSuggestionPremiumClick = (suggestionTitle) => {
  trackEvent('suggestion_premium_click', {
    suggestion_title: suggestionTitle
  });
};

// Add cost efficiency analysis tracking
export const trackCostEfficiencyPremiumClick = (section) => {
  trackEvent('cost_efficiency_premium_click', {
    section_name: section
  });
};

// Add PDF export tracking
export const trackPdfExport = () => {
  trackEvent('analysis_pdf_export', {
    timestamp: new Date().toISOString()
  });
};

// Add FAQ interaction tracking
export const trackFaqInteraction = (questionKey, panelIndex) => {
  trackEvent('faq_panel_expanded', {
    question: questionKey,
    panel_index: panelIndex
  });
};

// Payment tracking
export const trackPaymentSuccess = (amount, currency, transactionId) => {
  trackEvent('payment_success', {
    amount: amount,
    currency: currency,
    transaction_id: transactionId,
    timestamp: new Date().toISOString()
  });
};

export const trackPaymentFailure = () => {
  trackEvent('payment_failure', {
    timestamp: new Date().toISOString()
  });
};

// Add rate limit error tracking
export const trackRateLimitError = (fileSize, filename) => {
  trackEvent('trial_limit_error', {
    file_size: fileSize,
    filename: filename,
    timestamp: new Date().toISOString()
  });
};

// Add upload feedback tracking
export const trackUploadFeedback = (feedbackData) => {
  trackEvent('upload_feedback', {
    expectations: feedbackData.expectations,
    purpose: feedbackData.purpose,
    experience_level: feedbackData.experienceLevel,
    skipped: feedbackData.skipped,
    timestamp: new Date().toISOString()
  });
};

// Add user query analysis tracking
export const trackUserQueryPremiumClick = () => {
  trackEvent('user_query_premium_click', {
    section: 'user_requirements_analysis',
    timestamp: new Date().toISOString()
  });
};

// Add pricing click tracking
export const trackPricingClick = (source) => {
  trackEvent('pricing_click', {
    source: source, // 'nav_menu', 'mobile_menu', or 'user_menu'
    timestamp: new Date().toISOString()
  });
};
