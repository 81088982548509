<template>

    <v-card class="room-evaluation">
      <v-card-title class="text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold d-flex align-center">
        <v-icon icon="mdi-home-analytics" class="mr-2" />
        {{ $t('analysis.roomEval.title') }}
      </v-card-title>

      <v-card-text>
        <div v-if="loading" class="d-flex flex-column align-center ma-4">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mb-4"
          ></v-progress-circular>
          <span class="text-subtitle-2 text-sm-subtitle-1 text-medium-emphasis">{{ $t('analysis.roomEval.loading') }}</span>
        </div>

        <template v-else-if="analysis">
          <div 
            v-for="(room, index) in analysis.results"
            :key="room.raumName"
            class="room-section mb-6"
          >
            <div class="d-flex align-center justify-space-between mb-4">
              <span class="text-subtitle-1 text-sm-h6 text-md-h6 font-weight-bold">
                {{ room.raumName }}
              </span>
              <v-rating
                :model-value="room.gesamtbewertung"
                readonly
                density="compact"
                color="primary"
                length="5"
              ></v-rating>
            </div>

            <v-row class="evaluation-grid">
              <!-- Left column with 4 stacked cards -->
              <v-col 
                cols="12" 
                sm="12" 
                md="5" 
                class="pe-md-2"
              >
                <!-- Raumgröße -->
                <v-card class="evaluation-card mb-4">
                  <v-card-item>
                    <div class="d-flex align-center mb-4">
                      <v-icon icon="mdi-ruler-square" class="mr-2" size="24" color="primary"/>
                      <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">
                        {{ $t('analysis.roomEval.size') }}
                      </span>
                    </div>
                    <v-list-item density="compact">
                      <v-list-item-title>
                        <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap mb-2">
                          <template v-if="room.groesse.tatsaechlicheGroesse !== -1">
                            {{ room.groesse.tatsaechlicheGroesse }}m² 
                            <span class="text-medium-emphasis">({{ $t('analysis.roomEval.recommended') }}: {{ room.groesse.empfohleneGroesse }}m²)</span>
                          </template>
                          <template v-else>
                            <v-alert
                              type="info"
                              density="compact"
                              variant="text"
                              class="pa-0 text-wrap"
                            >
                              {{ $t('analysis.roomEval.sizeNotDetermined') }}
                              <span class="text-medium-emphasis text-wrap">
                                ({{ $t('analysis.roomEval.recommended') }}: {{ room.groesse.empfohleneGroesse }}m²)
                              </span>
                            </v-alert>
                          </template>
                        </div>
                        <v-rating
                          v-if="room.groesse.tatsaechlicheGroesse !== -1"
                          :model-value="calculateSizeScore(room.groesse.tatsaechlicheGroesse, room.groesse.empfohleneGroesse)"
                          readonly 
                          density="compact"
                          color="primary"
                          length="5"
                          class="mt-2 mb-3"
                        ></v-rating>
                      </v-list-item-title>
                    </v-list-item>
                  </v-card-item>
                </v-card>

                <!-- Möblierung -->
                <v-card class="evaluation-card mb-4">
                  <v-card-item>
                    <div class="d-flex align-center mb-4">
                      <v-icon icon="mdi-sofa" class="mr-2" size="24" color="primary"/>
                      <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">{{ $t('analysis.roomEval.furniture') }}</span>
                    </div>
                    <v-list-item density="compact">
                      <div class="d-flex align-center">
                        <v-rating
                          :model-value="room.moebel_bewertung.score"
                          readonly
                          density="compact"
                          color="primary"
                          length="5"
                        ></v-rating>
                      </div>
                      <PremiumContent 
                        :is-premium="isPremiumRoom(index)"
                        @upgrade="handlePremiumContent(room.raumName)"
                      >
                        <v-list-item-subtitle class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap mt-2">
                          <span v-html="formatMarkdownLite(room.moebel_beschreibung)"></span>
                        </v-list-item-subtitle>
                      </PremiumContent>
                    </v-list-item>
                  </v-card-item>
                </v-card>

                <!-- Fenster und Belichtung -->
                <v-card class="evaluation-card mb-4">
                  <v-card-item>
                    <div class="d-flex align-center mb-4">
                      <v-icon icon="mdi-window-open" class="mr-2" size="24" color="primary"/>
                      <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">{{ $t('analysis.roomEval.windows') }}</span>
                    </div>
                    <v-list-item density="compact">
                      <div class="d-flex align-center">
                        <v-rating
                          :model-value="room.fenster_bewertung.score"
                          readonly
                          density="compact"
                          color="primary"
                          length="5"
                        ></v-rating>
                      </div>
                      <PremiumContent 
                        :is-premium="isPremiumRoom(index)"
                        @upgrade="handlePremiumContent(room.raumName)"
                      >
                        <v-list-item-subtitle class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap mt-2">
                          <span v-html="formatMarkdownLite(room.fenster_beschreibung)"></span>
                        </v-list-item-subtitle>
                      </PremiumContent>
                    </v-list-item>
                  </v-card-item>
                </v-card>

                <!-- Energieeffizienz -->
                <v-card class="evaluation-card">
                  <v-card-item>
                    <div class="d-flex align-center mb-4">
                      <v-icon icon="mdi-lightning-bolt" class="mr-2" size="24" color="primary"/>
                      <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">{{ $t('analysis.roomEval.energy') }}</span>
                    </div>
                    <v-list-item density="compact">
                      <div class="d-flex align-center">
                        <v-rating
                          :model-value="room.energieeffizienz_bewertung.score"
                          readonly
                          density="compact"
                          color="primary"
                          length="5"
                        ></v-rating>
                      </div>
                      <PremiumContent 
                        :is-premium="isPremiumRoom(index)"
                        @upgrade="handlePremiumContent(room.raumName)"
                      >
                        <v-list-item-subtitle class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap mt-2">
                          <span v-html="formatMarkdownLite(room.energieeffizienz_beschreibung)"></span>
                        </v-list-item-subtitle>
                      </PremiumContent>
                    </v-list-item>
                  </v-card-item>
                </v-card>
              </v-col>

              <!-- Right column with room aspects -->
              <v-col 
                cols="12" 
                sm="12" 
                md="7" 
                class="ps-md-2"
              >
                <!-- Stauraum -->
                <v-card class="evaluation-card mb-4">
                  <v-card-item>
                    <div class="d-flex align-center mb-4">
                      <v-icon icon="mdi-storage-tank-outline" class="mr-2" size="24" color="primary"/>
                      <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">{{ $t('analysis.roomEval.storage') }}</span>
                    </div>
                    <v-list-item class="mb-4">
                      <div class="d-flex align-center mb-3">
                        <v-rating
                          :model-value="room.stauraum_bewertung.score"
                          readonly
                          density="compact"
                          color="primary"
                          length="5"
                        ></v-rating>
                      </div> 
                      <PremiumContent 
                        :is-premium="isPremiumRoom(index)"
                        @upgrade="handlePremiumContent(room.raumName)"
                      >
                        <v-list-item-subtitle class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap mt-2">
                          <span v-html="formatMarkdownLite(room.stauraum_beschreibung)"></span>
                        </v-list-item-subtitle>
                      </PremiumContent>
                    </v-list-item>
                  </v-card-item>
                </v-card>

                <!-- Flexibilität -->
                <v-card class="evaluation-card mb-4">
                  <v-card-item>
                    <div class="d-flex align-center mb-4">
                      <v-icon icon="mdi-sync" class="mr-2" size="24" color="primary"/>
                      <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">{{ $t('analysis.roomEval.flexibility') }}</span>
                    </div>
                    <v-list-item>
                      <div class="d-flex align-center">
                        <v-rating
                          :model-value="room.flexibilitaet_bewertung.score"
                          readonly
                          density="compact"
                          color="primary"
                          length="5"
                        ></v-rating>
                      </div>
                      <PremiumContent 
                        :is-premium="isPremiumRoom(index)"
                        @upgrade="handlePremiumContent(room.raumName)"
                      >
                        <v-list-item-subtitle class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap mt-2">
                          <span v-html="formatMarkdownLite(room.flexibilitaet_beschreibung)"></span>
                        </v-list-item-subtitle>
                      </PremiumContent>
                    </v-list-item>
                  </v-card-item>
                </v-card>

                <!-- Barrierefreiheit -->
                <v-card class="evaluation-card">
                  <v-card-item>
                    <div class="d-flex align-center mb-4">
                      <v-icon icon="mdi-wheelchair" class="mr-2" size="24" color="primary"/>
                      <span class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold">{{ $t('analysis.roomEval.barrierFree') }}</span>
                    </div>
                    <v-list-item>
                      <div class="d-flex align-center">
                        <v-rating
                          :model-value="room.barrierefrei_bewertung.score"
                          readonly
                          density="compact"
                          color="primary"
                          length="5"
                        ></v-rating>
                      </div>
                      <PremiumContent 
                        :is-premium="isPremiumRoom(index)"
                        @upgrade="handlePremiumContent(room.raumName)"
                      >
                        <v-list-item-subtitle class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap mt-2">
                          <span v-html="formatMarkdownLite(room.barrierefrei_beschreibung)"></span>
                        </v-list-item-subtitle>
                      </PremiumContent>
                      
                      <v-list-item v-if="room.tuerbreite || room.bewegungsflaeche">
                        <v-list>
                          <v-list-item>
                            <v-icon icon="mdi-door" class="mr-2" />
                            {{ room.tuerbreite }} m
                          </v-list-item>
                          <v-list-item>
                            <v-icon icon="mdi-rotate-orbit" class="mr-2" />
                            {{ room.bewegungsflaeche }} m
                          </v-list-item>
                          <v-list-item v-if="room.schwellen">
                            <v-icon icon="mdi-stairs" class="mr-2" />
                            {{ $t('analysis.roomEval.threshold') }}: {{ translateThreshold(room.schwellen) }}
                          </v-list-item>
                        </v-list>
                      </v-list-item>
                    </v-list-item>
                  </v-card-item>
                </v-card>
              </v-col>
            </v-row>

            <v-divider v-if="index !== analysis.results.length - 1" class="my-6"></v-divider>
          </div>
        </template>

        <v-alert
          v-else
          type="error"
          class="mt-2"
        >
          {{ $t('analysis.roomEval.error') }}
          <br />
          <v-btn
            color="primary"
            variant="text"
            class="mt-2"
            :loading="loading"
            @click="loadAnalysis"
          >
            <v-icon icon="mdi-refresh" class="mr-2" />
            {{ $t('common.retry') }}
          </v-btn>
        </v-alert>
      </v-card-text>
    </v-card>

</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/auth'
import PremiumContent from '@/components/common/PremiumContent.vue'
import { trackRoomPremiumClick } from '@/utils/analytics'
import { useStore } from 'vuex'
import { getIdToken } from 'firebase/auth'
import { formatMarkdownLite } from '@/utils/textFormatting'

const props = defineProps({
  imageUrl: {
    type: String,
    required: true
  }
})

const loading = ref(false)
// Convert analysis from ref to computed
const analysis = computed(() => store.state.analysisRooms || null)
const { locale, t } = useI18n()
const authStore = useAuthStore()
const hasPremiumAccess = computed(() => store.state.userHadPremiumWhenUploaded)
const store = useStore()

// Update isPremium logic to use credits
const isPremiumRoom = (index) => {
  if (hasPremiumAccess.value) return false
  return index > 0
}

const calculateSizeScore = (actual, recommended) => {
  if (actual === -1 || recommended === 0) return 0;
  const ratio = actual / recommended;
  if (ratio >= 0.9 && ratio <= 1.2) return 5;
  if (ratio >= 0.8 && ratio < 0.9 || ratio > 1.2 && ratio <= 1.4) return 4;
  if (ratio >= 0.7 && ratio < 0.8 || ratio > 1.4 && ratio <= 1.6) return 3;
  if (ratio >= 0.5 && ratio < 0.7 || ratio > 1.6 && ratio <= 1.8) return 2;
  return 1;
};

// Update premium content handler
const handlePremiumContent = (roomName) => {
  trackRoomPremiumClick(roomName)
  
  if (!hasPremiumAccess.value) {
    navigateToPricing()
  }
}

// Add navigation helper
const navigateToPricing = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
}

// Modify the loadAnalysis function
const loadAnalysis = async () => {
  if (loading.value) {
    return
  }

  // If we already have analysis data in store and the imageUrl hasn't changed, use that
  if (store.state.analysisRooms && store.state.lastAnalyzedImage === props.imageUrl) {
    loading.value = false
    return
  }

  // Show loading state while we load or wait for preloaded data
  loading.value = true
  
  try {
    let headers = {}
    if (authStore.isAuthenticated) {
      const token = await getIdToken(authStore.user)
      headers.Authorization = `Bearer ${token}`
    }

    const response = await axios.post('/api/analyze-barrier-free', {
      imageUrl: props.imageUrl,
      language: locale.value,
      model: store.state.modelQuality
    }, { headers })
    
    if (response.data?.data) {
      store.commit('setAnalysisRooms', response.data.data)
      store.commit('setLastAnalyzedImage', props.imageUrl)
      await authStore.fetchCredits()
    } else {
      throw new Error('Failed to load room analysis')
    }
  } catch (error) {
    console.error('Failed to load room analysis:', error)
  } finally {
    loading.value = false
  }
}

// Update onMounted to check for preloaded data
onMounted(() => {
  if (props.imageUrl && !store.state.analysisRooms) {
    loadAnalysis()
  } else if (store.state.analysisRooms) {
    // Use preloaded data
    loading.value = false
  }
})

const translateThreshold = (threshold) => {
  return threshold ? t(`analysis.roomEval.thresholds.${threshold}`) : '';
};
</script>

<style scoped>
.room-evaluation {
  border-radius: 8px;
}

.evaluation-grid {
  gap: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

/* Remove padding from columns by default */
:deep(.v-col) {
  padding: 0;
}

/* Add specific padding for the columns when side by side */
@media (min-width: 960px) {
  .pe-md-2 {
    padding-right: 8px;
  }

  .ps-md-2 {
    padding-left: 8px;
  }
}

/* Ensure cards have proper spacing */
.evaluation-card {
  height: auto;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid rgba(var(--v-border-color), 0.1);
  margin-bottom: 16px;
}

/* Remove margin from last card */
.evaluation-card:last-child {
  margin-bottom: 0;
}

/* Ensure right column card fills height */
.h-100 {
  height: 100%;
}

/* Add margin between cards only on mobile */
@media (max-width: 959px) {
  .evaluation-card.mb-4 {
    margin-bottom: 8px;
  }
}

/* Adjust card item padding based on screen size */
:deep(.v-card-item) {
  padding: 4px;
}

@media (min-width: 600px) {
  :deep(.v-card-item) {
    padding: 16px;
  }
}

/* Adjust room section padding */
.room-section {
  padding: 4px;
  border-radius: 8px;
  background-color: var(--v-background);
}

@media (min-width: 600px) {
  .room-section {
    padding: 8px;
  }
}

/* Remove padding from v-card-text on mobile */
:deep(.v-card-text) {
  padding: 4px;
}

/* Remove padding from list items on mobile */
:deep(.v-list-item) {
  padding: 6px;
}

@media (min-width: 600px) {
  :deep(.v-list-item) {
    padding: 0 8px;
  }
}

/* Adjust v-col padding on mobile */
:deep(.v-col) {
  padding: 2px !important;
}

@media (min-width: 600px) {
  :deep(.v-col) {
    padding: 12px !important;
  }
}

/* Adjust spacing between elements */
:deep(.mb-4) {
  margin-bottom: 6px !important;
}

@media (min-width: 600px) {
  :deep(.mb-4) {
    margin-bottom: 16px !important;
  }
}

:deep(.mb-3) {
  margin-bottom: 4px !important;
}

@media (min-width: 600px) {
  :deep(.mb-3) {
    margin-bottom: 12px !important;
  }
}

:deep(.mb-2) {
  margin-bottom: 3px !important;
}

@media (min-width: 600px) {
  :deep(.mb-2) {
    margin-bottom: 8px !important;
  }
}

/* Adjust icon margins */
.mr-2 {
  margin-right: 3px !important;
}

@media (min-width: 600px) {
  .mr-2 {
    margin-right: 8px !important;
  }
}

/* Remove old premium content styles */
.premium-content {
  user-select: none;
  filter: blur(4px);
}

/* Add new consistent blur style */
.premium-blur {
  filter: blur(4px);
  user-select: none;
  cursor: pointer;
  display: inline-block;
  transition: filter 0.2s ease;
}

.premium-blur:hover {
  filter: blur(6px);
}

.premium-message {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  padding: 4px 12px;
  font-size: 0.85rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  pointer-events: auto;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.premium-message-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.premium-item {
  position: relative;
}

.premium-item:hover .premium-message {
  display: flex;
}

/* Add styles for formatted text */
:deep(strong) {
  font-weight: 600;
}

:deep(em) {
  font-style: italic;
  color: var(--v-primary-base);
}

/* Typography and text styles */
.analysis-description {
  white-space: normal !important;
  overflow: visible;
  text-overflow: unset;
  word-wrap: break-word;
}

/* Remove custom typography styles and let Vuetify handle it */
:deep(.v-card-title) {
  font-size: inherit;
  font-weight: inherit;
}

:deep(h1), :deep(h2), :deep(h3) {
  margin: 0.5em 0;
}

:deep(ul), :deep(ol) {
  padding-left: 1.5em;
  margin: 0.5em 0;
}
</style>