<template>
  <div class="home">
    <v-container fluid class="pa-0 mobile-padding">
      <!-- Hero Section -->
      <HeroSection />

      <!-- Upload Section -->
      <UploadSection />

      <!-- Feature Demo Section -->
      <FeatureDemoSection />

      <!-- How It Works Section -->
      <HowItWorksSection />

      <!-- Testimonials Section -->
      <!--TestimonialsSection /-->

      <!-- Pricing Section -->
      <PricingSection />
      
      <!-- FAQ Section -->
      <FAQSection />
    </v-container>
  </div>
</template>

<script>
import FAQSection from '@/components/home/FAQSection.vue'
import FeatureDemoSection from '@/components/home/FeatureDemoSection.vue'
import HeroSection from '@/components/home/HeroSection.vue'
import UploadSection from '@/components/home/UploadSection.vue'
import HowItWorksSection from '@/components/home/HowItWorksSection.vue'
import PricingSection from '@/components/home/PricingSection.vue'
// import TestimonialsSection from '@/components/home/TestimonialsSection.vue'

export default {
  name: 'Home',
  components: {
    FAQSection,
    FeatureDemoSection,
    HeroSection,
    UploadSection,
    HowItWorksSection,
    PricingSection,
    // TestimonialsSection
  }
}
</script>

<style scoped>
/* Add responsive padding adjustments */
@media (max-width: 600px) {
  .mobile-padding :deep(section) {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  
  /* Adjust spacing between sections */
  .mobile-padding :deep(section + section) {
    margin-top: 1rem !important;
  }
}
</style>
