export const formatMarkdownLite = (text) => {
  if (!text) return '';
  
  return text
    // Headers - using smaller font sizes
    .replace(/^### (.*$)/gm, '<h3 class="text-subtitle-2 font-weight-medium mt-2 mb-1">$1</h3>')
    .replace(/^## (.*$)/gm, '<h2 class="text-subtitle-1 font-weight-medium mt-3 mb-2">$1</h2>')
    .replace(/^# (.*$)/gm, '<h1 class="text-h6 font-weight-bold mt-3 mb-2">$1</h1>')
    
    // Bold and Italic
    .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
    .replace(/\*(.*?)\*/g, '<em>$1</em>')
    
    // Lists
    .replace(/^\s*\d+\.\s+([^\n]*)/gm, '<li>$1</li>') // Ordered lists
    .replace(/^\s*[-*]\s+([^\n]*)/gm, '<li>$1</li>') // Unordered lists
    .replace(/(<li>.*<\/li>)\n+(?=<li>)/g, '$1') // Group consecutive list items
    .replace(/(<li>.*<\/li>)(?=\n[^<]|$)/g, '<ul>$1</ul>') // Wrap ul around items
    
    // Code blocks and inline code
    .replace(/`([^`]+)`/g, '<code>$1</code>')
    .replace(/```([^`]+)```/g, '<pre><code>$1</code></pre>')
    
    // Blockquotes
    .replace(/^> (.+)$/gm, '<blockquote>$1</blockquote>')
    
    // Links
    .replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank" rel="noopener">$1</a>')
    
    // Paragraphs
    .replace(/\n\s*\n/g, '</p><p>')
    .replace(/^([^<].*)/gm, (match) => {
      if (match.trim() && !match.startsWith('<')) {
        return `<p>${match}</p>`;
      }
      return match;
    })
    
    // Clean up empty paragraphs
    .replace(/<p>\s*<\/p>/g, '')
    
    // Fix any double-wrapped paragraphs
    .replace(/<p><p>/g, '<p>')
    .replace(/<\/p><\/p>/g, '</p>')
    
    // Line breaks (only for single newlines within paragraphs)
    .replace(/([^>\n])\n(?!<)([^<])/g, '$1<br>$2');
}; 