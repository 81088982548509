<template>

    <v-card>
      <v-card-title class="text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold">
        {{ $t('analysis.improvements.title') }}
      </v-card-title>
      <v-card-text>
        <!-- Timeline for larger screens -->
        <v-timeline 
          v-if="suggestions && suggestions.length > 0 && !$vuetify.display.smAndDown" 
          align="center"
        >
          <v-timeline-item
            v-for="(suggestion, index) in suggestions"
            :key="index"
            :dot-color="getPriorityColor(suggestion.priority)"
            size="small"
            :position="index % 2 === 0 ? 'right' : 'left'"
          >
            <div class="suggestion-content">
              <v-chip
                :color="getPriorityColor(suggestion.priority)"
                size="small"
                class="mb-2"
                label
                variant="tonal"
              >
                {{ $t(`analysis.improvements.priority.${suggestion.priority}`) }}
              </v-chip>
              <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold mb-2">
                <span v-if="isPremiumSuggestion(index)" 
                      class="premium-blur"
                      @click="handlePremiumContent(suggestion.title)">
                  {{ suggestion.title.split(' ')[0] }}
                </span>
                <span v-if="isPremiumSuggestion(index)">
                  {{ suggestion.title.split(' ').slice(1).join(' ') }}
                </span>
                <span v-else>{{ suggestion.title }}</span>
              </div>
              <PremiumContent 
                :is-premium="isPremiumSuggestion(index)"
                @upgrade="handlePremiumContent(suggestion.title)"
              >
                <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap">
                  {{ suggestion.description }}
                </div>
              </PremiumContent>
              <div class="text-subtitle-2 text-sm-subtitle-1 text-medium-emphasis">
                {{ $t('analysis.improvements.category') }}: 
                {{ $t(`analysis.improvements.categories.${suggestion.category}`) }}
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>

        <!-- Simple list for mobile -->
        <v-list
          v-if="suggestions && suggestions.length > 0 && $vuetify.display.smAndDown"
          class="suggestion-list"
        >
          <v-list-item
            v-for="(suggestion, index) in suggestions"
            :key="index"
            class="mb-4"
          >
            <div class="suggestion-content">
              <v-chip
                :color="getPriorityColor(suggestion.priority)"
                size="small"
                class="mb-2"
                label
                variant="tonal"
              >
                {{ $t(`analysis.improvements.priority.${suggestion.priority}`) }}
              </v-chip>
              <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold mb-2">
                <span v-if="isPremiumSuggestion(index)" 
                      class="premium-blur"
                      @click="handlePremiumContent(suggestion.title)">
                  {{ suggestion.title.split(' ')[0] }}
                </span>
                <span v-if="isPremiumSuggestion(index)">
                  {{ suggestion.title.split(' ').slice(1).join(' ') }}
                </span>
                <span v-else>{{ suggestion.title }}</span>
              </div>
              <PremiumContent 
                :is-premium="isPremiumSuggestion(index)"
                @upgrade="handlePremiumContent(suggestion.title)"
              >
                <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap">
                  {{ suggestion.description }}
                </div>
              </PremiumContent>
              <div class="text-subtitle-2 text-sm-subtitle-1 text-medium-emphasis">
                {{ $t('analysis.improvements.category') }}: 
                {{ $t(`analysis.improvements.categories.${suggestion.category}`) }}
              </div>
            </div>
          </v-list-item>
        </v-list>

        <div v-if="!suggestions || suggestions.length == 0" class="text-center pa-4 text-subtitle-2 text-sm-subtitle-1">
          {{ $t('analysis.improvements.noSuggestions') }}
        </div>
      </v-card-text>
    </v-card>

</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import PremiumContent from '@/components/common/PremiumContent.vue'
import { trackSuggestionPremiumClick } from '@/utils/analytics'

const store = useStore()

const hasPremiumAccess = computed(() => store.state.userHadPremiumWhenUploaded)

const isPremiumSuggestion = (index) => {
  if (hasPremiumAccess.value) return false
  return index > 0
}

const priorityOrder = { high: 3, medium: 2, low: 1 }

const sortedSuggestions = computed(() => {
  const suggestionsObject = store.state.analysis?.analysis?.suggestions;
  if (!suggestionsObject || typeof suggestionsObject !== 'object') {
    return [];
  }

  const transformedSuggestions = Object.entries(suggestionsObject).map(([key, value]) => ({
    title: value.title || key,
    description: value.description,
    priority: value.priority || 'low',
    category: value.category || 'general'
  }));

  return transformedSuggestions.sort((a, b) => {
    return priorityOrder[b.priority] - priorityOrder[a.priority];
  });
});

const suggestions = sortedSuggestions

const getPriorityColor = (priority) => {
  const colors = {
    high: 'error',
    medium: 'warning',
    low: 'success'
  }
  return colors[priority] || 'info'
}

// Add navigation helper
const navigateToPricing = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth'
  });
}

// Add premium content handler
const handlePremiumContent = (suggestionTitle) => {
  trackSuggestionPremiumClick(suggestionTitle)
  
  if (!hasPremiumAccess.value) {
    navigateToPricing()
  }
}
</script>

<style scoped>
/* Typography and text styles */
.analysis-description {
  white-space: normal !important;
  overflow: visible;
  text-overflow: unset;
  word-wrap: break-word;
}

/* Remove custom typography styles and let Vuetify handle it */
:deep(.v-card-title) {
  font-size: inherit;
  font-weight: inherit;
}

.suggestion-content {
  margin-bottom: 16px;
  max-width: 600px;
  @media (max-width: 600px) {
    width: 100%;
  }
}

/* List styles for mobile */
.suggestion-list {
  padding: 0;
}

:deep(.v-list-item) {
  padding: 8px 0;
}

/* Remove custom text styles and let Vuetify handle it */
:deep(.premium-content) {
  margin-bottom: 8px;
}

/* Adjust timeline spacing and alignment */
:deep(.v-timeline) {
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 24px;
  padding-right: 24px;
}

:deep(.v-timeline-item) {
  padding-inline: 24px;
}

:deep(.v-timeline-item--right) .suggestion-content {
  margin-left: 24px;
}

:deep(.v-timeline-item--left) .suggestion-content {
  margin-right: 24px;
}

/* Ensure the timeline dot aligns well on mobile */
:deep(.v-timeline-item__dot) {
  @media (max-width: 600px) {
    margin-inline-end: 8px;
  }
}

.premium-blur {
  filter: blur(4px);
  user-select: none;
  cursor: pointer;
  display: inline-block;
}

.premium-blur:hover {
  filter: blur(6px);
}
</style>
