<template>
  <v-app>
    <v-app-bar
      :elevation="0"
      class="px-0 app-bar-transition"
      :height="$vuetify.display.smAndDown ? '64' : '80'"
      :class="{
        'app-bar-hidden': shouldHideAppBar && $vuetify.display.smAndDown,
        'app-bar-visible': !shouldHideAppBar || !$vuetify.display.smAndDown
      }"
      fixed
    >
      <v-container class="px-6">
        <div class="d-flex align-center">
          <router-link to="/" class="text-decoration-none">
            <div class="d-flex align-center">
              <v-icon
                color="primary"
                size="32"
                class="mr-2"
              >
                mdi-floor-plan
              </v-icon>
              <span class="text-h6 font-weight-bold text-primary">{{ $t('nav.brand') }}</span>
            </div>
          </router-link> 
          
          <v-spacer></v-spacer>
          
          <div class="d-none d-md-flex align-center gap-2">
            <v-btn
              v-for="(item, i) in menuItems"
              :key="i"
              variant="text"
              class="text-none font-weight-medium"
              @click="item.onClick ? item.onClick() : goToSection(item.section, item.to)"
            >
              {{ $t(item.title) }}
            </v-btn>
            
            <LanguageSwitcher class="mx-2" @language-changed="handleLanguageChange" />
            
            <v-divider vertical class="mx-2"></v-divider>
            
            <template v-if="!authStore.isAuthenticated">
              <v-btn
                variant="text"
                to="/sign-in"
                class="text-none"
                elevation="0"
              >
                <v-icon icon="mdi-login" class="mr-1" size="small"></v-icon>
                {{ $t('nav.auth.signIn') }}
              </v-btn>
              <v-btn
                variant="elevated"
                to="/sign-up"
                color="primary"
                class="text-none"
                elevation="0"
              >
                <v-icon icon="mdi-account-plus" class="mr-1" size="small"></v-icon>
                {{ $t('nav.auth.signUp') }}
              </v-btn>
            </template>
            
            <v-menu v-else location="bottom end" transition="scale-transition">
              <template v-slot:activator="{ props }">
                <v-btn
                  class="text-none"
                  variant="text"
                  v-bind="props"
                >
                  <v-icon icon="mdi-account-circle" class="mr-1"></v-icon>
                  {{ authStore.user?.email }}
                  <v-icon icon="mdi-chevron-down" size="small" class="ml-1"></v-icon>
                </v-btn>
              </template>

              <v-list min-width="200">
                <v-list-item>
                  <template v-slot:prepend>
                    <v-icon icon="mdi-email" class="mr-2"></v-icon>
                  </template>
                  <v-list-item-title class="text-body-2">
                    {{ authStore.user?.email }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  style="cursor: pointer"
                  @click="handleCreditsClick"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-account-cash" class="mr-2"></v-icon>
                  </template>
                  <v-list-item-title class="text-body-2">
                    {{ authStore.credits }} Credits
                  </v-list-item-title>
                </v-list-item>

                <v-list-item
                  to="/analysis"
                  rounded="lg"
                  @click="store.commit('resetAnalysisData')"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-floor-plan" class="mr-2"></v-icon>
                  </template>
                  <v-list-item-title class="text-body-2">
                    {{ $t('nav.menu.analysis') }}
                  </v-list-item-title>
                </v-list-item>

                <v-divider class="my-2"></v-divider>

                <v-list-item
                  @click="handleSignOut"
                  color="error"
                >
                  <template v-slot:prepend>
                    <v-icon icon="mdi-logout" class="mr-2"></v-icon>
                  </template>
                  <v-list-item-title class="text-body-2">
                    {{ $t('nav.auth.signOut') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              v-if="showGetStartedButton"
              color="primary"
              class="text-none rounded-lg ml-4"
              elevation="0"
              @click="scrollToTop"
            >
              {{ $t('nav.getStarted') }}
              <v-icon right class="ml-2">mdi-arrow-right</v-icon>
            </v-btn>
          </div>
          
          <v-app-bar-nav-icon
            class="d-flex d-md-none"
            @click="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </div>
      </v-container>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
    >
      <v-list>
        <v-list-item
          v-for="(item, i) in menuItems"
          :key="i"
          @click="item.onClick ? item.onClick() : goToSection(item.section, item.to)"
          rounded="lg"
        >
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>

        <v-divider class="my-4"></v-divider>

        <template v-if="!authStore.isAuthenticated">
          <v-list-item
            to="/sign-in"
            rounded="lg"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-login"></v-icon>
            </template>
            <v-list-item-title>{{ $t('nav.auth.signIn') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/sign-up"
            rounded="lg"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-account-plus"></v-icon>
            </template>
            <v-list-item-title>{{ $t('nav.auth.signUp') }}</v-list-item-title>
          </v-list-item>
        </template>
        
        <template v-else>
          <v-list-item>
            <template v-slot:prepend>
              <v-icon icon="mdi-account-circle" class="mr-2"></v-icon>
            </template>
            <v-list-item-title class="text-body-2">
              {{ authStore.user?.email }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            style="cursor: pointer"
            @click="handleCreditsClick"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-account-cash" class="mr-2"></v-icon>
            </template>
            <v-list-item-title class="text-body-2">
              {{ authStore.credits }} Credits
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            to="/analysis"
            rounded="lg"
            @click="store.commit('resetAnalysisData')"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-floor-plan" class="mr-2"></v-icon>
            </template>
            <v-list-item-title class="text-body-2">
              {{ $t('nav.menu.analysis') }}
            </v-list-item-title>
          </v-list-item>

          <v-list-item
            @click="handleSignOut"
            color="error"
            rounded="lg"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-logout" class="mr-2"></v-icon>
            </template>
            <v-list-item-title class="text-body-2">
              {{ $t('nav.auth.signOut') }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <v-list-item>
          <LanguageSwitcher @language-changed="handleLanguageChange" />
        </v-list-item>

        <v-list-item v-if="showGetStartedButton" class="mt-4">
          <v-btn
            block
            color="primary"
            class="text-none"
            elevation="0"
            @click="scrollToTop"
          >
            {{ $t('nav.getStarted') }}
            <v-icon right class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main class="pt-16">
      <router-view></router-view>
    </v-main>

    <v-footer class="bg-background">
      <v-container>
        <v-row align="center" class="py-4">
          <v-col cols="12" md="6" class="text-center text-md-left">
            <span class="text-medium-emphasis text-body-2">
              {{ $t('nav.footer.copyright', { year: new Date().getFullYear() }) }}
            </span>
          </v-col>
          <v-col cols="12" md="6" class="text-center text-md-right">
            <v-btn
              v-for="(link, i) in footerLinks"
              :key="i"
              variant="text"
              class="text-none text-medium-emphasis text-body-2 mx-2"
              :to="link.to"
            >
              {{ $t(link.title) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script setup>
import { ref, watch, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import { useAuthStore } from '@/stores/auth';
import { auth } from '@/firebase/config';
import { trackPricingClick } from '@/utils/analytics';

const router = useRouter();
const store = useStore();
const drawer = ref(false);
const authStore = useAuthStore();

const menuItems = [
  { title: 'nav.menu.features', section: 'how-it-works', icon: 'mdi-help-circle-outline' },
  { title: 'nav.menu.pricing', section: 'pricing', icon: 'mdi-currency-usd', onClick: handlePricingClick },
  { title: 'nav.menu.about', section: 'faq', icon: 'mdi-frequently-asked-questions' },
  { title: 'nav.menu.articles', to: '/artikel', icon: 'mdi-text-box-multiple' }
];

const footerLinks = [
  { title: 'nav.footer.privacy', to: '/datenschutz' },
  { title: 'nav.footer.imprint', to: '/impressum' }
];

const showGetStartedButton = computed(() => {
  return router.currentRoute.value.path !== '/analysis';
});

const handleSignOut = async () => {
  await auth.signOut();
  store.commit('resetState');
  router.push('/');  
};

const handleSignInSuccess = () => {
  if (router.currentRoute.value.path === '/') {
    router.push('/analysis');
  }
};

watch(() => authStore.isAuthenticated, (newValue) => {
  if (newValue) {
    setTimeout(() => {
      handleSignInSuccess();
    }, 100);
  }
});

const goToSection = (section, to) => {
  if (to) {
    router.push(to);
    drawer.value = false;
    return;
  }

  if (router.currentRoute.value.path !== '/') {
    router.push('/')
    setTimeout(() => {
      const element = document.getElementById(section)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  } else {
    const element = document.getElementById(section)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }
  drawer.value = false
}

const handleCreditsClick = () => {
  trackPricingClick('user_menu');
  goToSection('pricing');
};

const scrollToTop = () => {
  if (router.currentRoute.value.path.includes('sign-')) {
    router.push('/');
  } else {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  drawer.value = false;
};

const handleLanguageChange = () => {
  // Force page reload
  window.location.reload();
};

const handlePricingClick = () => {
  const source = drawer.value ? 'mobile_menu' : 'nav_menu';
  trackPricingClick(source);
  goToSection('pricing');
};

const shouldHideAppBar = ref(false);

// Handler for pinning tabs: pin (fixed at top:0) if the page is scrolled more than 10px,
// and revert to its original sticky position when at the very top.
const handleScroll = () => {
  // Get current scroll position
  const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
  
  // Only show the app bar when we're at the very top (with a small threshold)
  if (currentScrollPosition < 10) {
    shouldHideAppBar.value = false;
    return;
  }
  
  // Hide the app bar for all other scroll positions
  shouldHideAppBar.value = true;
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style>
:root {
  --v-background-base: var(--background);
  --v-primary-base: var(--primary);
  --v-primary-lighten1: var(--primary-light);
  --v-primary-darken1: var(--primary-dark);
}

.v-application {
  background-color: var(--background) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-h6 {
  line-height: 1.2;
  font-weight: 700 !important;
}

.v-btn {
  font-weight: 600;
}

.v-app-bar {
  background-color: var(--background) !important;
  transition: transform 0.3s ease;
}

.v-app-bar--hidden {
  transform: translateY(-100%);
}

.v-app-bar--visible {
  transform: translateY(0);
}

.klaro {
  z-index: 2000 !important;
}

.klaro .cookie-modal {
  z-index: 2001 !important;
}

.klaro .cookie-notice {
  z-index: 2001 !important;
  position: fixed !important;
}

/* Add these new styles after the existing ones */

/* For Webkit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.1);
}

/* Ensure the main content area is scrollable */
.v-main {
  overflow-y: auto !important;
  padding-top: var(--v-app-bar-height, 64px) !important;
}

/* Ensure the app container allows scrolling */
.v-application {
  overflow-y: auto !important;
}

/* Klaro Modal Styling */
.klaro .cookie-modal {
  z-index: 2001 !important;
}

.klaro .cookie-modal .cm-modal {
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  max-height: 98vh !important;
  top: 50% !important;
  position: fixed !important;
  border-radius: 8px !important;
  width: 90% !important;
  max-width: 640px !important;
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
}

.klaro .cookie-modal .cm-modal .cm-header {
  padding: 1em !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.klaro .cookie-modal .cm-modal .cm-body {
  padding: 1em !important;
}

.klaro .cookie-modal .cm-modal .cm-footer {
  padding: 1em !important;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-buttons {
  display: flex !important;
  gap: 8px !important;
}

.klaro .cookie-modal .cm-modal .cm-footer .cm-btn {
  padding: 8px 16px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
}

/* Add overlay styling */
.klaro .cookie-modal .cm-bg {
  background: rgba(255, 255, 255, 0.1) !important;
  backdrop-filter: blur(2px) !important;
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
}

/* Prevent scrolling when modal is open */
body.cm-modal-open {
  overflow: hidden !important;
}

/* Make sure modal content is scrollable if needed */
.klaro .cookie-modal .cm-modal .cm-body {
  max-height: calc(98vh - 200px) !important;
  overflow-y: auto !important;
}

/* Style the service items */
.klaro .cookie-modal .cm-services {
  gap: 8px !important;
}

.klaro .cookie-modal .cm-service {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  padding: 1em !important;
  background: rgba(0, 0, 0, 0.02) !important;
}

/* Improve button styling */
.klaro .cm-btn {
  transition: all 0.2s ease !important;
}

.klaro .cm-btn:hover {
  opacity: 0.9 !important;
  transform: translateY(-1px) !important;
}

.klaro .cm-btn.cm-btn-success {
  background: var(--primary) !important;
}

.klaro .cm-btn.cm-btn-decline {
  background: #dc3545 !important;
}

/* Add responsive styles for the app bar */
@media (max-width: 600px) {
  .v-app-bar .v-toolbar__content {
    padding: 4px 0 !important;
  }

  .v-app-bar .text-h6 {
    font-size: 1.1rem !important;
  }

  .v-app-bar .v-icon {
    font-size: 24px !important;
  }

  .v-app-bar .v-container {
    padding-top: 4px !important;
    padding-bottom: 0px !important;
  }
}

/* App bar transition styles */
.app-bar-transition {
  transition: transform 0.3s ease-in-out !important;
}

.app-bar-hidden {
  transform: translateY(-100%) !important;
}

.app-bar-visible {
  transform: translateY(0) !important;
}

/* Update main content padding */
.v-main {
  padding-top: 64px !important;
}

@media (min-width: 960px) {
  .v-main {
    padding-top: 80px !important;
  }
}
</style>
