<template>
  <v-card class="user-query-card">
    <v-card-title class="text-subtitle-1 text-sm-h6 text-md-h5 font-weight-bold">
      <v-icon icon="mdi-comment-question" color="primary" class="mr-2"></v-icon>
      {{ $t('analysis.userIntent.title') }}
    </v-card-title>
    <v-card-text class="d-flex flex-column flex-grow-1">
      <div v-if="hasValidFeedback" class="query-content">
        <div class="mb-4">
          <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold mb-1">
            {{ $t('analysis.userIntent.expectations') }}
          </div>
          <p class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap">
            {{ feedback.expectations }}
          </p>
        </div>
        
        <div class="mb-4">
          <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 font-weight-bold mb-1">
            {{ $t('analysis.userIntent.purpose') }}
          </div>
          <v-chip
            color="primary"
            size="small"
            variant="tonal"
            class="text-subtitle-2"
          >
            {{ $t(`upload.feedback.purpose.${feedback.purpose}`) }}
          </v-chip>
        </div>

        <div class="evaluation-section">
          <template v-if="isLoadingEvaluation">
            <div class="d-flex flex-column align-center ma-4">
              <v-progress-circular
                indeterminate
                color="primary"
                class="mb-4"
              ></v-progress-circular>
              <span class="text-subtitle-2 text-sm-subtitle-1 text-medium-emphasis">{{ $t('analysis.userIntent.loading') }}</span>
            </div>
          </template>
          <div v-else-if="evaluation" :class="{ 'premium-item': !hasPremiumAccess }">
            <div class="d-flex justify-end mt-4">
              <div v-tooltip="tooltipText">
                <v-btn
                  color="primary"
                  variant="tonal"
                  prepend-icon="mdi-pencil"
                  @click="showFeedbackModal = true"
                  :disabled="!hasPremiumAccess"
                  class="text-subtitle-2 text-sm-subtitle-1"
                >
                  {{ $t('analysis.userIntent.newRequirements') }}
                </v-btn>
              </div>
            </div>
            <PremiumContent 
              :is-premium="!hasPremiumAccess"
              :preview-content="evaluation.substring(0, 550)"
              @upgrade="handlePremiumContent"
            >
              <div class="text-subtitle-2 text-sm-subtitle-1 text-md-subtitle-1 analysis-description text-wrap" 
                   v-html="formatMarkdownLite(evaluation)">
              </div>
            </PremiumContent>
          </div>
        </div>
      </div>
      <div v-else class="empty-state">
        <v-icon
          icon="mdi-information"
          color="info"
          size="large"
          class="mb-3"
        ></v-icon>
        <p class="text-subtitle-2 text-sm-subtitle-1 text-medium-emphasis text-center">
          {{ $t('analysis.userIntent.noIntent') }}
        </p>
        <p class="text-subtitle-2 text-sm-subtitle-1 text-medium-emphasis text-center mt-2">
          {{ $t('analysis.userIntent.noIntentHint') }}
        </p>
        <v-btn
          color="primary"
          variant="tonal"
          class="mt-4 text-subtitle-2 text-sm-subtitle-1"
          prepend-icon="mdi-plus"
          @click="showFeedbackModal = true"
        >
          {{ $t('analysis.userIntent.addRequirements') }}
        </v-btn>
      </div>
    </v-card-text>

    <UploadFeedbackModal
      v-model="showFeedbackModal"
      @submit="handleFeedbackSubmit"
      @skip="showFeedbackModal = false"
      :filename="currentFilename"
    />
  </v-card>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import UploadFeedbackModal from '@/components/upload/UploadFeedbackModal.vue'
import PremiumContent from '@/components/common/PremiumContent.vue'
import axios from 'axios'
import { formatMarkdownLite } from '@/utils/textFormatting'
import { trackUserQueryPremiumClick } from '@/utils/analytics'
import { useI18n } from 'vue-i18n'

const store = useStore()
const { t } = useI18n()

const feedback = computed(() => store.state.userIntent)
const hasValidFeedback = computed(() => {
  return feedback.value && 
         feedback.value.expectations?.trim() && 
         feedback.value.purpose?.trim();
})

const evaluation = ref(null)
const isLoadingEvaluation = ref(false)

const fetchEvaluation = async () => {
  if (!hasValidFeedback.value || !store.state.analysis?.analysis) return
  
  isLoadingEvaluation.value = true
  try {
    const headers = {}
    if (store.state.auth?.user) {
      const token = await store.state.auth.user.getIdToken()
      headers.Authorization = `Bearer ${token}`
    }
    
    const response = await axios.post('/api/user_query', {
      userIntent: feedback.value,
      analysis: store.state.analysis.analysis,
      imageUrl: store.state.analysis.imageUrl || null
    }, { headers })
    
    if (response.data.success) {
      evaluation.value = response.data.evaluation
    }
  } catch (error) {
    console.error('Failed to fetch evaluation:', error)
  } finally {
    isLoadingEvaluation.value = false
  }
}

// Watch for changes in feedback or analysis to trigger evaluation
watch([feedback, () => store.state.analysis?.analysis], ([newFeedback, newAnalysis]) => {
  if (newFeedback && newAnalysis) {
    fetchEvaluation()
  } else {
    evaluation.value = null
  }
}, { immediate: true })

// Extract filename from the imageUrl in the store
const currentFilename = computed(() => {
  const imageUrl = store.state.analysis?.imageUrl;
  if (!imageUrl) return '';
  // Extract filename from path like '/uploads/filename.jpg'
  return imageUrl.split('/').pop();
})

const showFeedbackModal = ref(false)

const handleFeedbackSubmit = (feedbackData) => {
  store.commit('setUserIntent', feedbackData)
  showFeedbackModal.value = false
}

// Add premium access check
const hasPremiumAccess = computed(() => store.state.userHadPremiumWhenUploaded)

// Update premium content handler to use the new tracking method
const handlePremiumContent = () => {
  trackUserQueryPremiumClick()
  
  if (!hasPremiumAccess.value) {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth'
    });
  }
}

const tooltipText = computed(() => {
  if (!store.state.auth?.user) {
    return t('analysis.userIntent.loginRequired');
  }
  if (!hasPremiumAccess.value) {
    return t('analysis.userIntent.creditsRequired');
  }
  return '';
});
</script>

<style scoped>
.user-query-card {
  height: 100%;
  border: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
}

/* Typography and text styles */
.analysis-description {
  white-space: normal !important;
  overflow: visible;
  text-overflow: unset;
  word-wrap: break-word;
}

/* Standardize text styles */
.analysis-description :deep(p) {
  margin: 0.75rem 0;
  padding: 0.25rem 0;
  line-height: 1.6;
}

.analysis-description :deep(ul),
.analysis-description :deep(ol) {
  padding-left: 1.5rem;
  margin-bottom: 0.75rem;
}

.analysis-description :deep(li) {
  margin-bottom: 0.25rem;
}

.analysis-description :deep(code) {
  background-color: var(--v-surface-variant);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-size: 0.9em;
}

.analysis-description :deep(blockquote) {
  border-left: 3px solid var(--v-primary-base);
  margin: 0.75rem 0;
  padding-left: 1rem;
  color: var(--text-secondary);
}

.analysis-description :deep(a) {
  color: var(--v-primary-base);
  text-decoration: none;
}

.analysis-description :deep(a:hover) {
  text-decoration: underline;
}

.analysis-description :deep(h1),
.analysis-description :deep(h2),
.analysis-description :deep(h3) {
  color: var(--text-primary);
  font-weight: 500;
  line-height: 1.2;
}

/* Remove custom typography styles and let Vuetify handle it */
:deep(.v-card-title) {
  font-size: inherit;
  font-weight: inherit;
}

:deep(.v-card-text) {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
  text-align: center;
}

.evaluation-section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid var(--border-color);
}

/* Add styles for formatted text */
:deep(strong) {
  font-weight: 600;
}

:deep(em) {
  font-style: italic;
  color: var(--v-primary-base);
}

.premium-item {
  position: relative;
}

.premium-item:hover .premium-message {
  display: flex;
}
</style> 