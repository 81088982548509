// Klaro configuration
export const klaroConfig = {
    // With cookie consent, Google Analytics shouldn't start automatically
    googleAnalytics: false,

    // Styling
    styling: {
        theme: ['light', 'center', 'wide'],
    },

    // General configuration
    acceptAll: true,
    hideDeclineAll: true,
    hideLearnMore: false,
    noticeAsModal: true,
    default: true,         // Changed to true to pre-select all options
    modal: true,
    poweredBy: null,
    hideToggleAll: false,   // Changed to false to show granular controls
    hideFooter: true,      // Hide the footer completely
    mustConsent: true,
    disablePoweredBy: true,
    storageMethod: 'cookie',
    storageName: 'grundriss_ai_cookie_consent',
    cookieExpiresAfterDays: 365,
    htmlTexts: true,        // Allow HTML in texts
    embedded: false,        // Not embedded in another page

    // Translations
    translations: {
        de: {
            privacyPolicyUrl: '/datenschutz',
            consentModal: {
                title: 'Informationen die wir sammeln',
                description: 'Hier können Sie einsehen und anpassen, welche Information wir über Sie sammeln.\n',
            },
            consentNotice: {
                description: 'Wir verwenden Cookies und ähnliche Technologien, um Ihre Erfahrung auf unserer Website zu verbessern.',
                learnMore: 'Mehr erfahren',
            },
            purposes: {
                analytics: 'Besucher-Statistiken',
            },
            ok: 'Alle akzeptieren',
            acceptAll: 'Alle akzeptieren',
            acceptSelected: 'Auswahl bestätigen',
            decline: 'Nur notwendige',
            save: 'Speichern',
            service: {
                purpose: 'Zweck',
                purposes: 'Zwecke',
            }
        },
        en: {
            privacyPolicyUrl: '/privacy',
            consentModal: {
                title: 'Information we collect',
                description: 'Here you can see and customize the information that we collect about you.\n',
            },
            consentNotice: {
                description: 'We use cookies and similar technologies to improve your experience on our website.',
                learnMore: 'Learn more',
            },
            purposes: {
                analytics: 'Analytics',
                marketing: 'Marketing',
            },
            ok: 'Accept all',
            acceptAll: 'Accept all',
            acceptSelected: 'Accept selected',
            decline: 'Essential only',
            save: 'Save',
            service: {
                purpose: 'Purpose',
                purposes: 'Purposes',
            }
        }
    },

    // Services configuration
    services: [
        {
            name: 'essential',
            title: 'Essential Cookies',
            purposes: ['essential'],
            required: true,  // This service cannot be disabled
            default: true,
            optOut: false,
            cookies: [
                // Session cookie
                [/^session.*$/i, '/', 'grundriss-ai.de'],
                // CSRF token cookie
                [/^XSRF-TOKEN.*$/i, '/', 'grundriss-ai.de'],
                // Language preference cookie
                ['language', '/', 'grundriss-ai.de']
            ],
            translations: {
                de: {
                    title: 'Notwendige Cookies',
                    description: 'Diese Cookies sind für die grundlegenden Funktionen der Website unbedingt erforderlich. Sie ermöglichen die Navigation und Grundfunktionen wie Anmeldung und Spracheinstellungen.'
                },
                en: {
                    title: 'Essential Cookies',
                    description: 'These cookies are strictly necessary for the basic functions of the website. They enable navigation and basic features like login and language settings.'
                }
            }
        },
        {
            name: 'google-analytics',
            title: 'Google Analytics',
            purposes: ['statistics'],
            required: false,
            default: true,
            optOut: true,
            onlyOnce: false,
            cookies: [
                [/^_ga.*$/i, '/', 'grundriss-ai.de'],
                [/^_gid.*$/i, '/', 'grundriss-ai.de'],
            ],
            callback: function(consent, service) {
                // Update consent state using Google Consent Mode
                if (typeof window.gtag === 'function') {
                    // Use separate update for analytics consent
                    window.gtag("consent", "update", {
                        'analytics_storage': consent ? "granted" : "denied"
                    });
                    
                    // Debug logging
                    console.debug('GA Consent Updated:', {
                        consent: consent,
                        service: service.name,
                        timestamp: new Date().toISOString()
                    });
                }
            },
            translations: {
                de: {
                    title: 'Google Analytics',
                    description: 'Hilft uns zu verstehen, wie Besucher mit unserer Website interagieren. Wir erfassen dabei:\n' +
                               '- Bildupload-Events (Dateigröße und Status)\n' +
                               '- Registrierungsdialog-Öffnungen\n' +
                               '- Registrierungsversuche\n\n' +
                               'Alle Daten werden anonymisiert erfasst.'
                }
            }
        },
        {
            name: 'google-ads',
            title: 'Google Ads',
            purposes: ['marketing'],
            required: false,
            default: true,  // Pre-select marketing
            optOut: true,
            onlyOnce: true,
            cookies: [
                [/^_gcl.*$/i, '/', 'grundriss-ai.de'],  // Google Ads cookies
            ],
            callback: function(consent, service) {
                // Update consent state using Google Consent Mode
                if (typeof window.gtag === 'function') {
                    window.gtag("consent", "update", {
                        ad_storage: consent ? "granted" : "denied",
                        ad_user_data: consent ? "granted" : "denied",
                        ad_personalization: consent ? "granted" : "denied"
                    });

                    // Debug logging
                    console.debug('Google Ads Consent Updated:', {
                        consent: consent,
                        service: service.name,
                        timestamp: new Date().toISOString()
                    });
                }
            },
            translations: {
                de: {
                    title: 'Google Ads',
                    description: 'Hilft uns, die Effektivität unserer Werbekampagnen zu messen und relevante Anzeigen anzuzeigen.\n' +
                               'Die Daten werden verwendet für:\n' +
                               '- Conversion-Tracking\n' +
                               '- Anzeigenanpassung\n' +
                               '- Remarketing'
                }
            }
        },
        {
            name: 'posthog',
            title: 'PostHog Analytics',
            purposes: ['statistics', 'session_recording'],
            required: false,
            default: true,
            cookies: [
                [/^ph_.*$/i, '/', 'grundriss-ai.de'],
                ['posthog_distinct_id', '/', 'grundriss-ai.de']
            ],
            callback: function(consent, service) {
                if (window.posthog) {
                    try {
                        if (consent) {
                            window.posthog.opt_in_capturing();
                            // Only try to start session recording if consent is given
                            try {
                                window.posthog.startSessionRecording();
                            } catch (recordingError) {
                                console.debug('Failed to start session recording:', recordingError);
                            }
                        } else {
                            // First stop recording before opting out
                            try {
                                window.posthog.stopSessionRecording();
                            } catch (recordingError) {
                                console.debug('Failed to stop session recording:', recordingError);
                            }
                            // Then opt out of capturing
                            window.posthog.opt_out_capturing();
                        }
                        
                        // Debug logging
                        console.debug('Posthog Consent Updated:', {
                            consent: consent,
                            service: service.name,
                            timestamp: new Date().toISOString()
                        });
                    } catch (error) {
                        console.debug('Error updating PostHog consent:', error);
                    }
                } else {
                    console.debug("PostHog not available - cannot change consent");
                }
            },
            translations: {
                de: {
                    title: 'PostHog Analytics',
                    description: 'Hilft uns die Nutzung der Website zu verstehen und zu verbessern. ' +
                                'Mit Ihrer Zustimmung zeichnen wir auch anonymisierte Sitzungen auf, ' +
                                'um Nutzungsprobleme besser zu verstehen.'
                },
                en: {
                    title: 'PostHog Analytics',
                    description: 'Helps us understand and improve website usage. ' +
                                'With your consent, we also record anonymized sessions ' +
                                'to better understand usage problems.'
                }
            }
        }
    ],
    // Add clear purpose descriptions
    purposeOrder: ['essential', 'statistics', 'marketing'],
    purposes: {
        essential: {
            name: 'essential',
            title: {
                de: 'Notwendige Cookies',
                en: 'Essential Cookies'
            },
            description: {
                de: 'Diese Cookies sind für die grundlegenden Funktionen der Website unbedingt erforderlich.',
                en: 'These cookies are strictly necessary for the basic functions of the website.'
            }
        },
        statistics: {
            name: 'statistics',
            title: {
                de: 'Statistiken',
                en: 'Statistics'
            },
            description: {
                de: 'Diese Cookies helfen uns zu verstehen, wie Besucher mit unserer Website interagieren.',
                en: 'These cookies help us understand how visitors interact with our website.'
            }
        },
        marketing: {
            name: 'marketing',
            title: {
                de: 'Marketing',
                en: 'Marketing'
            },
            description: {
                de: 'Diese Cookies werden verwendet, um Werbung relevanter für Sie zu gestalten.',
                en: 'These cookies are used to make advertising more relevant to you.'
            }
        },
        session_recording: {
            name: 'session_recording',
            title: {
                de: 'Sitzungsaufzeichnung',
                en: 'Session Recording'
            },
            description: {
                de: 'Anonymisierte Aufzeichnung von Website-Besuchen zur Verbesserung der Benutzerfreundlichkeit. ' +
                    'Alle sensiblen Daten werden automatisch maskiert.',
                en: 'Anonymized recording of website visits to improve usability. ' +
                    'All sensitive data is automatically masked.'
            }
        }
    }
};
